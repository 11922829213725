import React from "react";
import { Link } from "react-router-dom";

const HomePageRE = () => {
  const topics = [
    { id: 1, name: "Introduction to React", path: "/topic/react" },
    { id: 2, name: "JavaScript Basics", path: "/topic/js-basics" },
  ];

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <h1>Learning Platform</h1>
      <h2>Thursday September 19th, 2024</h2>
      <ul>
        <li>
          <Link to="/html">HTML</Link>
        </li>
        <li>
          <Link to="/css">CSS</Link>
        </li>
        <li>
          <Link to="/bootstrap">Bootstrap</Link>
        </li>
      </ul>
      <h2>Thursday September 26th, 2024</h2>
      <ul>
        <li>
          <Link to="/javascript">JavaScript</Link>
        </li>
      </ul>
      <h2>Thursday October 3rd, 2024</h2>
      <ul>
        <li>
          <Link to="/spa">SPA vs MPA</Link>
        </li>
        <li>
          <Link to="/jsx">JSX</Link>
        </li>
        <li>
          <Link to="/rintro">React Introduction</Link>
        </li>
      </ul>
      <h2>Thursday October 10th, 2024</h2>
      <ul>
        <li>
          <Link to="/reactapp">Create-react-app</Link>
        </li>
      </ul>
      <h2>Thursday October 17th, 2024</h2>
      <ul>
        <li>
          <Link to="/reactreuse">Reusable components and props</Link>
        </li>
        <li>
          <Link to="/reactClass">
            Class based component vs functional and lifecycles
          </Link>
        </li>
        <li>
          <Link to="/reactTodoOne">A simple Todo List 1 of 3</Link>
        </li>
        <li>
          <Link to="/reactTodoTwo">A simple Todo List 2 of 3</Link>
        </li>
        <li>
          <Link to="/reactTodoThree">A simple Todo List 3 of 3</Link>
        </li>

        <li>Mid-term Project</li>
      </ul>
      <h2>Thursday October 24th, 2024</h2>
      <ul>
        <li>
          <Link to="/reactuse">UseEffect hook explained</Link>
        </li>
        <li>
          <Link to="/reactrouter">React Router</Link>
        </li>
      </ul>
      <h2>Thursday October 31th, 2024</h2>
      <ul>
        <li>
          <Link to="/reactredux">Redux</Link>
        </li>
        <li>Assignment 04</li>
      </ul>
      <h2>Thursday November 14th, 2024</h2>
      <ul>
        <li>
          <Link to="/rapi">API</Link>
        </li>
        <li>
          <Link to="/rcusthook">custom hooks</Link>
        </li>
        <li>
          <b>CRUD Example with api + activity : Files on moodle</b>
        </li>
        <li>
          <b>Final Project</b>
        </li>
      </ul>
    </div>
  );
};

export default HomePageRE;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";

export class VcliSetup extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Vue.js CLI Setup</h1>

        <h2 id="direct">Nodes.js</h2>
        <TextExplanation
          textspeech={
            "1 - Go to the Node.js website. 2 - Download the LTS version of Node.js for your operating system. 3 - Follow the installation instructions to complete the setup. Note: Installing Node.js will also install npm (Node Package Manager), which is required to setup Vue.js CLI."
          }
          text={
            <ol>
              <li>Go to the Node.js website.</li>
              <li>
                Download the LTS version of Node.js for your operating system.
              </li>
              <li>
                Follow the installation instructions to complete the setup.
              </li>
              <li>
                Note: Installing Node.js will also install npm (Node Package
                Manager), which is required to setup Vue.js CLI.
              </li>
            </ol>
          }
        />
        <b>
          Check the Node.js version and npm version using the following commands
          from the terminal:
        </b>
        <CodeDisplay code="node -v" language="sh" />
        <CodeDisplay code="npm -v" language="sh" />
        <h2 id="dbind">Vue.js CLI setup</h2>
        <b>run the following command to setup Vue.js CLI</b>
        <CodeDisplay code="npm install -g @vue/cli" language="sh" />
        <h2 id="events">Creating a new project</h2>
        <b>
          After installation, run the following command to verify if Vue CLI is
          installed correctly:
        </b>
        <CodeDisplay code="vue --version" language="sh" />
        <b>1 - To create a new project:</b>
        <CodeDisplay code="vue create my-vue-app" language="sh" />
        <b>2 - Choose Manually select features</b>
        <img src="./images/s01.png" alt="" />
        <b>3 - Select Babel, Router, Linter/Formatter</b>
        <img src="./images/s02.png" alt="" />
        <b>4 - Select Vue.js 3</b>
        <img src="./images/s03.png" alt="" />
        <b>5 - Select ESLint + Prettier</b>
        <img src="./images/s04.png" alt="" />
        <b>6 - Choose Lint on save</b>
        <img src="./images/s05.png" alt="" />
        <b>7 - Choose In Package.json</b>
        <img src="./images/s06.png" alt="" />

        <hr />
        <p>reference: https://vuejs.org/</p>
      </div>
    );
  }
}

export default VcliSetup;

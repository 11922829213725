import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicAuthBack extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Authentication: Backend</h1>
        <TextExplanation
          text={
            "In this example we will continue using the MCR pattern to create the back-end for the authentication"
          }
        />
        We'll introduce 2 additional libraries in this context:
        <ul>
          <li>
            <b>bvrypt: </b>"bcryptjs" will be used to hash the user's password
            which will make it more secured than storing it as plain text in the
            database at the registration, also will be used at the login to
            compare the provided password with the hashed one
          </li>
          <li>
            <b>jwt: </b>"jasonwebtoken" will serve as a middleware to sign and
            generate the authentication token with the front-end
          </li>
        </ul>
        <p>
          Start by initializing your project and install the required libraries:
        </p>
        <CodeDisplay
          code="npm init -y
npm install express mysql2 bcryptjs jsonwebtoken body-parser cors"
          language="sh"
        />
        <p>
          create the project structure respecting the MCR pattern: the{" "}
          <b>server</b>,<b>userController</b>, <b>userModel</b>,{" "}
          <b>authRoutes</b> and finally the <b>authMiddleware</b>
          for the jwt.
        </p>
        <ol>
          <li>
            <h2>server.js</h2>

            <img src="./images/ab01.png" alt="" />
          </li>
          <li>
            <h2>userModel.js</h2>
            <p>
              Will serve as the layer interactiong with the database providing
              with the database connection and executing 3 queries serving the
              controller
            </p>
            <b>1- find a user by username</b>
            <img src="./images/ab02.png" alt="" />
            <b>2- find a user by id</b>
            <img src="./images/ab03.png" alt="" />
            <b>3- create a new user</b>
            <img src="./images/ab04.png" alt="" />
          </li>
          <li>
            <h2>userController.js</h2>
            will the methods to <b>Regiser</b> and <b>Login</b> And{" "}
            <b>
              Granting/Denying access to a protected route (the user profile
              page in this example
            </b>
            <br />
            <br />
            <b>1- Register</b>
            <p>
              To register a user we'll check if the user already exist bfor
              proceeding by using the findUserByUsername query from the
              userModel
            </p>
            <p>
              if the user doesn't exist it'll proceed by hashing the provided
              passwrod using bcrypt, calling the createUser query from the
              userModel and passing the username and the hashed password to it
              and will handle the errors
            </p>
            <img src="./images/ab05.png" alt="" />
            <br />
            <br />
            <b>2- Login</b>
            <p>
              Will use the findUserByUsername query from the userModel and
              handle the errors
            </p>
            <p>
              if the user was found, it will proceed the the compare method from
              bcrypt to check the credentials
            </p>
            <p>
              if the credentials match it will pass the user id, the jwt_secret,
              the expiry delay to the jwt to sign and return the token
            </p>
            <img src="./images/ab06.png" alt="" />
            <b>
              3- Get the user profile method used by the protected route to
              display the user profile information
            </b>
            <img src="./images/ab07.png" alt="" />
          </li>
          <li>
            <h2>Routes</h2>
            <p>
              contains the routes to <b>register</b>, <b>login</b> and the{" "}
              <b>user profile's protected route</b>
            </p>
            <p>
              The protected route is invoking the jwt middleware to verify the
              authentication token before retirieving the user profile
              information
            </p>
            <img src="./images/ab08.png" alt="" />
          </li>
          <li>
            <h2>JWT Middleware: authMiddleware.js</h2>
            <p>
              the jwt middleware is invoked by the request to the protectedt
              route to verify the user's token
            </p>
            <p>
              if the verification fails it will break and return with the
              unauthorized code
            </p>
            <p>
              if verification OK it will proceed by calling the protected route
              (next())
            </p>
            <img src="./images/ab09.png" alt="" />
          </li>
          <li>
            <h2>Database used in this example:</h2>
          </li>
          <img src="./images/ab10.png" alt="" />
        </ol>
        <hr />
        <p>reference: https://nodejs.org/docs/latest/api/</p>
      </div>
    );
  }
}

export default TopicAuthBack;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class Vprops extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Vue.js Props</h1>
        <TextExplanation
          text={
            "Using the previous example of Vue.js components, instead of storing the students information inside the child component. we are going to receive it in the parent View and pass it to the child component using props"
          }
        />
        <img src="./images/cp01.png" alt="" />
        <h2>
          Receive the data in the parent view instead of the child component
        </h2>
        <b>
          Then pass it to the component :students pointing to the array students
        </b>
        <img src="./images/pr01.png" alt="" />
        <b>
          Now from the child component we could access the props by defining the
          property: props
        </b>
        <img src="./images/pr02.png" alt="" />
        <b>
          Props could also be defined as an object describing itd type and if
          it's requiered or not
        </b>

        <hr />
        <p>reference: https://vuejs.org/</p>
      </div>
    );
  }
}

export default Vprops;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicReactApp extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>Create-react-app</h1>
        Create React App is an officially supported way to create single-page
        React applications. It offers a modern build setup with no
        configuration. Other recently introduced methods will be discussed later
        during the course.
        <p>to install the create-react-app CLI you can use the npm command:</p>
        <CodeDisplay code="npm install -g create-react-app" language="sh" />
        <p>
          now you're ready to use npx (node package runner) to create a new
          react app by running the following code in the terminal:
        </p>
        <CodeDisplay code="npx create-react-app my-app" language="sh" />
        <p>
          a new react app folder will be created with the basic template
          installed ready for us to start developing our app, you can run the
          app to test in the browser by runnig the command:
        </p>
        <CodeDisplay code="npm start" language="sh" />
        <h2>React.js project structure(Demo)</h2>
        <img src="./images/re01.png" alt="" />
        <p>
          now remove the starter template by deleting the code inside App.js to
          display a message Hello from React!
        </p>
        <img src="./images/re02.png" alt="" />
        <h2 style={{ border: "3px solid black", padding: "20px" }}>
          Activity (10 minutes): Go to the breakout rooms, and reproduce the
          last exercise from Assignment 02 inside your react app
        </h2>
        <h2>Conditional rendering</h2>
        <CodeDisplay
          code="import React from 'react';

function Welcome() {
  return <h1>Welcome, Student!</h1>;
}

function Goodbye() {
  return <h1>Goodbye, Students!</h1>;
}

function App() {
  const welcome = true; // Set this to true or false to change the rendering

  return (
    <div className='App'>
      {/* Conditional rendering */}
      {welcome ? <Welcome /> : <Goodbye />}
    </div>
  );
}

export default App;
"
          language="javascript"
        />
        <h2>React state management (useState) and events(Demo)</h2>
        <p>
          State Management: The useState hook is used to manage the welcome
          variable, which controls what gets rendered.
        </p>
        <p>
          Conditional Rendering: The ternary expression decides which component
          to render based on the value of welcome.
        </p>
        <CodeDisplay
          code="import React, { useState } from 'react';

function Welcome() {
  return <h1>Welcome, Student!</h1>;
}

function Goodbye() {
  return <h1>Goodbye, Students!</h1>;
}

function App() {
  const [welcome, setWelcome] = useState(true);

  return (
    <div className='App'>
      {/* Conditional rendering */}
      {welcome ? <Welcome /> : <Goodbye />}
      
      {/* Button to toggle the state */}
      <button onClick={() => setWelcome(!welcome)}>
        Toggle Message
      </button>
    </div>
  );
}

export default App;

"
          language="javascript"
        />
        <hr />
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default TopicReactApp;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicTodoOne extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>Creating a simple todo list</h1>
        <ol>
          <h2>Separating the logic with a TodoForm, TodoItem and TodoList</h2>
          <li>
            <b>App.js</b>

            <CodeDisplay
              code='import React, { useState } from "react";
import TodoForm from "./components/TodoForm";
import TodoList from "./components/TodoList";

const App = () => {
  const [todos, setTodos] = useState([]);
  const [editTask, setEditTask] = useState(null);  // Track the task being edited

  const handleAdd = (text) => {
    const newTodo = { id: Date.now(), text };
    setTodos([...todos, newTodo]);
  };

  const handleEdit = (id, newText) => {
    const updatedTodos = todos.map((todo) =>
      todo.id === id ? { ...todo, text: newText } : todo
    );
    setTodos(updatedTodos);
    setEditTask(null);  // Exit edit mode
  };

  const handleDelete = (id) => {
    setTodos(todos.filter((todo) => todo.id !== id));
  };

  return (
    <div className="App">
      <h1>To-Do List</h1>
      <TodoForm 
        onSubmit={editTask ? handleEdit : handleAdd} 
        editTask={editTask} 
      />
      <TodoList 
        todos={todos} 
        onEdit={setEditTask} 
        onDelete={handleDelete} 
      />
    </div>
  );
};

export default App;
'
              language="javascript"
            />
          </li>
          <li>
            <b>TodoList.js</b>

            <CodeDisplay
              code='import React from "react";
import TodoItem from "./TodoItem";

const TodoList = ({ todos, onEdit, onDelete }) => (
  <ul>
    {todos.length > 0 ? (
      todos.map((todo) => (
        <TodoItem 
          key={todo.id} 
          todo={todo} 
          onEdit={onEdit} 
          onDelete={onDelete} 
        />
      ))
    ) : (
      <p>No tasks available. Add a task to get started!</p>
    )}
  </ul>
);

export default TodoList;
'
              language="javascript"
            />
          </li>
          <li>
            <b>TodoForm.js</b>

            <CodeDisplay
              code='import React, { useState, useEffect } from "react";

const TodoForm = ({ onSubmit, editTask }) => {
  const [input, setInput] = useState("");

  // Pre-fill the input field when editing a task
  useEffect(() => {
    if (editTask) {
      setInput(editTask.text);
    }
  }, [editTask]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    if (editTask) {
      onSubmit(editTask.id, input);  // Call edit handler
    } else {
      onSubmit(input);  // Call add handler
    }

    setInput("");  // Reset input field
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder={editTask ? "Edit task..." : "Add a new task..."}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <button type="submit">{editTask ? "Update" : "Add"}</button>
    </form>
  );
};

export default TodoForm;
'
              language="javascript"
            />
          </li>
          <li>
            <b>TodoItem.js</b>

            <CodeDisplay
              code='import React from "react";

const TodoItem = ({ todo, onEdit, onDelete }) => (
  <li>
    {todo.text}
    <button onClick={() => onEdit(todo)}>Edit</button>
    <button onClick={() => onDelete(todo.id)}>Delete</button>
  </li>
);

export default TodoItem;
'
              language="javascript"
            />
          </li>
        </ol>

        <hr />
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default TopicTodoOne;

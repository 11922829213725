import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicTodoOne extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>Creating a simple todo list</h1>
        <ol>
          <h2>
            Separating the TodoList and adding function to delete a specific
            todo
          </h2>
          <li>
            <b>App.js</b>

            <CodeDisplay
              code='import React, { useState } from "react";
import TodoList from "./components/TodoList";

const App = () => {
  const [todos, setTodos] = useState([]);
  const [input, setInput] = useState("");  // Track input field value

  const handleAdd = (e) => {
    e.preventDefault();
    if (!input.trim()) return;  // Ignore empty input

    const newTodo = { id: Date.now(), text: input };
    setTodos([...todos, newTodo]);
    setInput("");  // Clear input after adding
  };

  const handleDelete = (id) => {
    setTodos(todos.filter((todo) => todo.id !== id));
  };

  return (
    <div className="App">
      <h1>To-Do List</h1>
      <form onSubmit={handleAdd}>
        <input
          type="text"
          placeholder="Add a task..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button type="submit">Add</button>
      </form>
      <TodoList todos={todos} onDelete={handleDelete} />
    </div>
  );
};

export default App;
'
              language="javascript"
            />
          </li>
          <li>
            <b>TodoList.js</b>

            <CodeDisplay
              code='import React from "react";

const TodoList = ({ todos, onDelete }) => (
  <ul>
    {todos.length > 0 ? (
      todos.map((todo) => (
        <li key={todo.id}>
          {todo.text}
          <button onClick={() => onDelete(todo.id)}>Delete</button>
        </li>
      ))
    ) : (
      <p>No tasks available. Add one to get started!</p>
    )}
  </ul>
);

export default TodoList;
'
              language="javascript"
            />
          </li>
        </ol>

        <hr />
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default TopicTodoOne;

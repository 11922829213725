import React, { Component } from "react";
import TextExplanation from "./TextExplanation";
import CodeDisplay from "./CodeDisplay";
import { Link } from "react-router-dom";

export class TopicVIntro extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Vue.js</h1>
        <ol>
          <li>
            <a href="#intro">Introduction</a>
          </li>
          <li>
            <a href="#adv">Advantages of Using Vue.js</a>
          </li>
          <li>
            <a href="#installing">Installing Vue.js</a>
          </li>
          <li>
            <a href="#vdata">Vue.js Data</a>
          </li>
          <li>
            <a href="#methods">Vue.js methods</a>
          </li>
          <li>
            <a href="#lifecycle">
              Vue.js components lifecycle stages and hooks
            </a>
          </li>
        </ol>
        <h2 id="intro">Introduction to Vue.js</h2>
        <TextExplanation
          text={
            "Vue.js is a progressive JavaScript framework used for building user interfaces and single-page applications (SPAs). It is designed to be easy to integrate with other libraries or projects, allowing developers to progressively adopt its features without a steep learning curve. Vue is lightweight, flexible, and approachable, making it an excellent choice for both beginners and experienced developers.Vue.js primarily focuses on the view layer of an application, which handles how data is presented to users. It uses a declarative rendering system, meaning developers describe what they want the UI to look like, and Vue efficiently updates and renders the components whenever data changes."
          }
        />
        <h2 id="adv">Advantages of Using Vue.js</h2>
        <ol>
          <li>
            <b>Easy to Learn and Use:</b>
          </li>
          <p>
            Vue's syntax is simple and intuitive, making it easy for developers,
            even with basic HTML, CSS, and JavaScript knowledge, to start
            building applications.
          </p>
          <li>
            <b>Reactive Data Binding:</b>
          </li>
          <p>
            Vue offers a powerful reactive data binding system that
            automatically updates the UI whenever the underlying data changes.
            This makes data management within the UI effortless and consistent.
          </p>
          <li>
            <b>Component-Based Architecture:</b>
          </li>
          <p>
            Vue encourages a component-based structure, where applications are
            divided into reusable components. This promotes modularity and makes
            code easier to manage, maintain, and scale.
          </p>
          <li>
            <b>Progressive Framework:</b>
          </li>
          <p>
            Vue can be integrated into an existing project incrementally. You
            can use Vue just for specific parts of an app, like handling a
            single widget or page, and later scale to a full application if
            needed.
          </p>
          <li>
            <b>Performance:</b>
          </li>
          <p>
            Vue is known for its high performance, thanks to its lightweight
            nature and efficient virtual DOM. It ensures that the app runs
            smoothly even in complex use cases.
          </p>
          <li>
            <b>Large Ecosystem and Community:</b>
          </li>
          <p>
            Vue has a rich ecosystem of official libraries (like Vue Router for
            navigation and Vuex for state management) and tools (like the Vue
            CLI for project scaffolding). Its growing community offers support,
            tutorials, and plugins for various use cases.
          </p>
          <li>
            <b>Two-Way Data Binding:</b>
          </li>
          <p>
            Vue simplifies the connection between the UI and the data with
            two-way data binding, which means any changes in the UI are
            reflected in the data model and vice versa.
          </p>
          <li>
            <b>
              Vue DevTools:{" "}
              <i>(Demo install Vue dev tools for Google Chrome)</i>
            </b>
          </li>
          <p>
            The official Vue DevTools extension is available for browsers,
            allowing developers to inspect their Vue components, view component
            hierarchy, and debug efficiently during development.
          </p>
        </ol>
        <h2 id="installing">Installing Vue.js via CDN</h2>
        <ol>
          <li>
            Start by setting up a simple HTML page. This will serve as the
            foundation of your Vue app.
          </li>
          <CodeDisplay
            code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Vue.js App</title></head><body><div id="app"><!-- Vue will take control of this section --><h1>{{ message }}</h1></div><!-- Include Vue.js from CDN here --><script src="https://unpkg.com/vue@3"></script><!-- Add your script for Vue below --><script>// Vue app will go here</script></body></html>'
            language="html"
          />
          <b>Link to Vue.js CDN</b>
          <CodeDisplay
            code='<script src="https://unpkg.com/vue@3"></script>'
            language="html"
          />
          <li>
            Use the Destructuring methose to extract the createApp method from
            Vue.js, and mount the root of your application. in this example the
            div with the is "app"
          </li>
          <CodeDisplay
            code='const { createApp } = Vue;

createApp({

}).mount("#app");'
            language="javascript"
          />
        </ol>
        <h2 id="vdata">Vue.js data</h2>
        The data function is returning an object with the different data
        properties.
        <CodeDisplay
          code='const { createApp } = Vue;

createApp({
    data(){
        return{
            name:"Fadi",
            city:"Montreal"
        };
    },
}).mount("#app");'
          language="javascript"
        />
        In order to call these properties to be displayed in the HTML page you
        need to include them in double curly braces expression aka 'Moustache'
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Vue.js App</title></head><body><div id="app"><!-- Vue will take control of this section --><h1> Hello my name is {{ name }} and i live in {{city}}</h1></div><!-- Include Vue.js from CDN here --><script src="https://unpkg.com/vue@3"></script><!-- Add your script for Vue below --><script>const { createApp } = Vue;createApp({data(){return{name:"Fadi",city:"Montreal"};},}).mount("#app");</script></body></html>'
          language="html"
        />
        <h2 id="methods">Methods</h2>
        <p>
          The app methods should go into the methods block as in this example
        </p>
        <CodeDisplay
          code='const { createApp } = Vue;

createApp({
    data(){
        return{
            name:"Fadi",
            city:"Montreal"
        };
    },
      methods: {
    greet() {
      return "Good morning from Vue Js";
    },
  },
}).mount("#app");'
          language="javascript"
        />
        and could be called from inside the moustache expression
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Vue.js App</title></head><body><div id="app"><!-- Vue will take control of this section --><h1>{{greet()}}</h1></div><!-- Include Vue.js from CDN here --><script src="https://unpkg.com/vue@3"></script><!-- Add your script for Vue below --><script>const { createApp } = Vue;createApp({data(){return{name:"Fadi",city:"Montreal"};},}).mount("#app");</script></body></html>'
          language="html"
        />
        <h2 id="lifecycle">Vue.js components lifecycle stages and hooks</h2>
        <TextExplanation
          text={
            "In Vue.js, components go through several stages from the moment they are created until they are destroyed. These stages form what is called the component lifecycle. Vue provides lifecycle hooks, which are methods that allow developers to execute code at specific points in a component's lifecycle. The main stages are: Creation, Mounting, Updating, and Destruction. Each stage consists of specific lifecycle hooks.Here’s a simplified breakdown of the Vue component lifecycle with an illustration of when each hook is called:"
          }
        />
        <ol>
          <li>
            <b>Creation (Before Mounting):</b>
          </li>
          <p>
            At this stage, the component is being set up, but it is not yet
            rendered in the DOM
          </p>
          <b>Lifecycle hooks:</b>
          <ul>
            <li>
              beforeCreate: Called after the instance has been initialized but
              before data observation and event/watcher setup.
            </li>
            <li>
              created: Called after the instance is created. At this point,
              reactive data is set up, but the component has not been added to
              the DOM.
            </li>
          </ul>
          <li>
            <b>Mounting (Adding to DOM):</b>
          </li>
          <p>
            The component is now rendered in the DOM. The el option (or
            template) is compiled, and the virtual DOM is created.
          </p>
          <b>Lifecycle hooks:</b>
          <ul>
            <li>
              beforeMount: Called right before the component is mounted to the
              DOM.
            </li>
            <li>
              mounted: Called after the component is mounted. At this point, the
              component is fully rendered, and you can access the rendered DOM
              elements.
            </li>
          </ul>
          <li>
            <b>Updating (Reactive Changes):</b>
          </li>
          <p>
            This stage occurs whenever the component's reactive data changes.
            Vue efficiently updates only the part of the DOM that needs to be
            changed.
          </p>
          <b>Lifecycle hooks:</b>
          <ul>
            <li>
              beforeUpdate: Called before the DOM is updated with new data.
            </li>
            <li>
              updated: Called after the DOM has been updated. This is useful for
              reacting to DOM changes after the component's data has changed.
            </li>
          </ul>
          <li>
            <b>Destruction (Component Removal):</b>
          </li>
          <p>
            At this stage, the component is about to be removed from the DOM,
            and all its event listeners, watchers, and child components are
            cleaned up.
          </p>
          <b>Lifecycle hooks:</b>
          <ul>
            <li>
              beforeDestroy: Called before the component is destroyed. The
              component is still fully functional at this point.
            </li>
            <li>
              destroyed: Called after the component is destroyed. The component
              is completely removed, and everything associated with it (events,
              child components, etc.) is also destroyed.
            </li>
          </ul>
        </ol>
        <hr />
        <p>reference: https://vuejs.org/</p>
      </div>
    );
  }
}

export default TopicVIntro;

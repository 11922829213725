import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicNodeExp extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Backend: Node.js / Express.js</h1>
        <ol>
          <li>
            <a href="#direct">Vue.js Directives</a>
          </li>
          <li>
            <a href="#dbind">Vue.js Data binding</a>
          </li>
          <li>
            <a href="#events">Vue.js Events</a>
          </li>
          <li>
            <a href="#twoway">Vue.js Two way data binding</a>
          </li>
          <li>
            <a href="#looping">Vue.js Looping data</a>
          </li>
          <li>
            <a href="#comp">Vue.js components</a>
          </li>
        </ol>
        <h2 id="direct">Vue.js Directives</h2>
        <TextExplanation
          text={
            "Vue.js directives are prefixed with v- preceding an html attribute and used to bind data or attach behaviors to DOM elements. They allow you to create dynamic interfaces by reacting to changes in the application’s data. Some common Vue.js directives include:"
          }
        />
        <ul>
          <li>
            <b>v-bind: </b>Dynamically binds attributes or properties to
            expressions.
          </li>
          <li>
            <b>v-model:</b> Creates two-way data binding on form input elements.
          </li>
          <li>
            <b>v-if, v-else-if, v-else: </b>Conditionally renders elements based
            on truthy or falsy expressions.
          </li>
          <li>
            <b>v-for: </b>Loops through arrays or objects and renders a list of
            items.
          </li>
          <li>
            <b>v-on: </b>Attaches event listeners to elements.
          </li>
        </ul>
        <h2 id="dbind">Data binding v-bind (Demo)</h2>
        <b>Example:</b>
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><title>Document</title></head><body><div id="app"><a href="">test</a></div><script src="https://unpkg.com/vue@3/dist/vue.global.js"></script><script src="./main.js"></script></body></html>'
          language="html"
        />
        <CodeDisplay
          code='const { createApp } = Vue;

createApp({
    data(){
        return{
            link:"https://www.google.ca/",
        };
    },
      
}).mount("#app");'
          language="javascript"
        />
        <b>Solution:</b>
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><title>Document</title></head><body><div id="app"><a v-bind:href="link">test</a></div><script src="https://unpkg.com/vue@3/dist/vue.global.js"></script><script src="./main.js"></script></body></html>'
          language="html"
        />
        <h2 id="events">Events v-on</h2>
        <b>Example:</b>
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><title>Document</title></head><body><div id="app"><button v-on:click="testfunction">click me</button></div><script src="https://unpkg.com/vue@3/dist/vue.global.js"></script><script src="./main.js"></script></body></html>'
          language="html"
        />
        <CodeDisplay
          code='const { createApp } = Vue;

createApp({
  data() {
    return {
      link: "https://www.google.ca/",
    };
  },
  methods: {
    testfunction() {
      console.log("clicked");
    },
  },
}).mount("#app");'
          language="javascript"
        />
        <h2 id="twoway">Two way data binding</h2>
        <b>Example:</b>
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><title>Document</title></head><body><div id="app"><<input v-model="message" placeholder="Type something" /><p>Message: {{ message }}</p><button v-on:click="changeMsg">Change message</button></div><script src="https://unpkg.com/vue@3/dist/vue.global.js"></script><script src="./main.js"></script></body></html>'
          language="html"
        />
        <CodeDisplay
          code='const { createApp } = Vue;

createApp({
  data() {
    return {
      message: "",
    };
  },
  methods: {
    changeMsg() {
      this.message = "the message was changed";
    },
  },
}).mount("#app");'
          language="javascript"
        />
        <h2 id="looping">Looping data (v-for)</h2>
        <b>Example 1 string array:</b>
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><title>Document</title></head><body><div id="app"><ul>
        <li v-for="(student, i) in students" :key="i">{{student}}</li></ul></div><script src="https://unpkg.com/vue@3/dist/vue.global.js"></script><script src="./main.js"></script></body></html>'
          language="html"
        />
        <CodeDisplay
          code='const { createApp } = Vue;

createApp({
  data() {
    return {
      students: ["Fadi","Steve","Benoit","Mike"],
    };
  },
  
}).mount("#app");'
          language="javascript"
        />
        <b>Example 2 objects array:</b>
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><title>Document</title></head><body><div id="app"><div v-for="student in students" :key="student.id">
        <h2>Student name: {{student.name}}</h2>
        <p>
          {{student.name}} is {{student.age}} years old and his student id is:
          {{student.id}}
        </p>
      </div></div><script src="https://unpkg.com/vue@3/dist/vue.global.js"></script><script src="./main.js"></script></body></html>'
          language="html"
        />
        <CodeDisplay
          code='const { createApp } = Vue;

createApp({
  data() {
    return {
      students: [
        {
          name: "Fadi",
          age: 45,
          id: 1,
        },
        {
          name: "Steve",
          age: 36,
          id: 2,
        },
        {
          name: "Benoit",
          age: 28,
          id: 3,
        },
        {
          name: "Mike",
          age: 33,
          id: 4,
        },
      ],
    };
  },
}).mount("#app");'
          language="javascript"
        />
        <h2 id="comp">Components (templates)</h2>
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><title>Document</title></head><body><div id="app"><my_component></my_component></div><script src="https://unpkg.com/vue@3/dist/vue.global.js"></script><script src="./main.js"></script></body></html>'
          language="html"
        />
        <CodeDisplay
          code='const { createApp } = Vue;

const my_component = {
  template: "<h1> Hello from my first component</h1>",
};

createApp({
  data() {
    return {};
  },
  components: {
    my_component,
  },
}).mount("#app");'
          language="javascript"
        />
        <hr />
        <p>reference: https://vuejs.org/</p>
      </div>
    );
  }
}

export default TopicNodeExp;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicAClass extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>Class based component vs functional and lifecycles</h1>
        <ol>
          <li>
            <h2>Class-based Components</h2>
            <p>
              These were the primary way to write components before React 16.8
              introduced hooks. They use ES6 classes and manage their own state
              with this.state.
            </p>
            <b>Pros</b>
            <ul>
              <li>
                State and lifecycle methods: Easily manage state and lifecycle
                with methods like componentDidMount and componentDidUpdate.
              </li>
              <li>
                Readable for OOP developers: Familiar structure for developers
                from an object-oriented programming background.
              </li>
              <li>
                Error Boundaries: Only class components can define error
                boundaries using componentDidCatch.
              </li>
            </ul>
            <b>Cons</b>
            <ul>
              <li>
                Verbose and complex: More boilerplate with constructor,
                this.state, and binding event handlers (this.handleClick =
                this.handleClick.bind(this)).
              </li>
              <li>
                Harder to reuse logic: Sharing logic between components requires
                higher-order components (HOCs) or render props, making code
                harder to follow.
              </li>
              <li>
                Limited optimization: Not as performant as functional components
                due to additional overhead.
              </li>
            </ul>

            <li>
              <h2> Functional Components</h2>
              <p>
                These are plain JavaScript functions that receive props and
                return JSX. After React 16.8, functional components gained the
                ability to manage state and lifecycle through hooks.
              </p>
              <b>Pros</b>
              <ul>
                <li>
                  Simpler and more concise: No need for constructors or binding
                  methods. Code is easier to read and maintain.
                </li>
                <li>
                  Hooks enable powerful features: Manage state with useState and
                  side effects with useEffect.
                </li>
                <li>
                  Easier to share logic: Custom hooks allow the reuse of
                  stateful logic across components.
                </li>
                <li>
                  Better performance: Functional components are lighter as they
                  don’t carry the class overhead.
                </li>
              </ul>
              <b>Cons</b>
              <ul>
                <li>
                  Hooks can be confusing for beginners: Learning hooks (like
                  useEffect and dependency arrays) has a learning curve.
                </li>
                <li>
                  Error boundaries not supported: Functional components can’t
                  act as error boundaries directly.
                </li>
                <li>
                  Overuse of hooks: Can lead to complex logic if hooks are not
                  managed properly (known as "callback hell").
                </li>
              </ul>
            </li>
            <li>
              <h2>Copmarison Table: Class vs. Functional Components</h2>
              <img src="./images/recl01.png" alt="" />
            </li>
            <li>
              <h2>
                Example of a class component call the different component
                lifecycles:
              </h2>
              <CodeDisplay
                code="import React, { Component } from 'react';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
      isRunning: false
    };
  }

  componentDidMount() {
    console.log('Component mounted');
    this.startTimer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isRunning !== this.state.isRunning) {
      if (this.state.isRunning) {
        console.log('Timer started');
        this.timerID = setInterval(() => {
          this.setState({ time: this.state.time + 1 });
        }, 1000);
      } else {
        console.log('Timer stopped');
        clearInterval(this.timerID);
      }
    }
  }

  componentWillUnmount() {
    console.log('Component will unmount');
    clearInterval(this.timerID);
  }

  startTimer = () => {
    this.setState({ isRunning: true });
  };

  stopTimer = () => {
    this.setState({ isRunning: false });
  };

  render() {
    return (
      <div>
        <h2>Timer: {this.state.time} seconds</h2>
        <button onClick={this.startTimer}>Start</button>
        <button onClick={this.stopTimer}>Stop</button>
      </div>
    );
  }
}

export default Timer;
"
                language="javascript"
              />
            </li>
          </li>
        </ol>

        <hr />
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default TopicAClass;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePageEF from "./components/HomePageEF";
import HomePageRE from "./components/HomePageRE";
import TopicHtml from "./components/TopicHtml";
import TopicCSS from "./components/TopicCSS";
import TopicBS from "./components/TopicBS";
import TopicJs from "./components/TopicJs";
import TopicJsRe from "./components/TopicJsRe";
import TopicSpa from "./components/TopicSpa";
import TopicVIntro from "./components/TopicVIntro";
import TopicVue from "./components/TopicVue";
import VcliSetup from "./components/cli/VcliSetup";
import VprojectFiles from "./components/cli/VprojectFiles";
import ViewsRoutes from "./components/cli/ViewsRoutes";
import Vcomponents from "./components/cli/Vcomponents";
import Vprops from "./components/cli/Vprops";
import Vforms from "./components/cli/Vforms";
import Vapi from "./components/cli/Vapi";
import ReactIntro from "./components/reactCDN/ReactIntro";
import ReactJsx from "./components/reactCDN/ReactJsx";
import TopicApiDoc from "./components/backend/TopicApiDoc";
import TopicBackend from "./components/backend/TopicBackend";
import TopicMCR from "./components/backend/TopicMCR";
import TopicNodeExp from "./components/backend/TopicNodeExp";
import TopicNodeMysql from "./components/backend/TopicNodeMysql";
import TopicPrFront from "./components/backend/TopicPrFront";

import TopicAuthBack from "./components/auth/TopicAuthBack";
import TopicAuthFront from "./components/auth/TopicAuthFront";
import TopicReactApp from "./components/react/TopicReactApp";

import TopicAReuse from "./components/reactCrud/TopicAReuse";
import TopicAClass from "./components/reactCrud/TopicAClass";
import TopicTodoOne from "./components/reactCrud/TopicTodoOne";
import TopicTodoTwo from "./components/reactCrud/TopicTodoTwo";
import TopicTodoThree from "./components/reactCrud/TopicTodoThree";

import TopicUseEff from "./components/reactUse/TopicUseEff";
import TopicRouter from "./components/reactUse/TopicRouter";

import TopicRedux from "./components/reactreduc/TopicRedux";

import Rapi from "./components/reactapi/Rapi";
import Rcusth from "./components/reactapi/Rcusth";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/EF" element={<HomePageEF />} />
        <Route path="/react" element={<HomePageRE />} />
        <Route path="/html" element={<TopicHtml />} />
        <Route path="/css" element={<TopicCSS />} />
        <Route path="/bootstrap" element={<TopicBS />} />
        <Route path="/js" element={<TopicJs />} />
        <Route path="/javascript" element={<TopicJsRe />} />
        <Route path="/spa" element={<TopicSpa />} />
        <Route path="/vuejsintro" element={<TopicVIntro />} />
        <Route path="/vuejs" element={<TopicVue />} />
        <Route path="/clisetup" element={<VcliSetup />} />
        <Route path="/vproject" element={<VprojectFiles />} />
        <Route path="/viewsroutes" element={<ViewsRoutes />} />
        <Route path="/Vcomponents" element={<Vcomponents />} />
        <Route path="/Vprops" element={<Vprops />} />
        <Route path="/Vforms" element={<Vforms />} />
        <Route path="/api" element={<Vapi />} />
        <Route path="/jsx" element={<ReactJsx />} />
        <Route path="/rintro" element={<ReactIntro />} />

        <Route path="/apidoc" element={<TopicApiDoc />} />
        <Route path="/backend" element={<TopicBackend />} />
        <Route path="/mcr" element={<TopicMCR />} />
        <Route path="/nodeexp" element={<TopicNodeExp />} />
        <Route path="/nodemysql" element={<TopicNodeMysql />} />
        <Route path="/projfront" element={<TopicPrFront />} />

        <Route path="/authback" element={<TopicAuthBack />} />
        <Route path="/authfront" element={<TopicAuthFront />} />
        <Route path="/reactapp" element={<TopicReactApp />} />

        <Route path="/reactreuse" element={<TopicAReuse />} />
        <Route path="/reactClass" element={<TopicAClass />} />
        <Route path="/reactTodoOne" element={<TopicTodoOne />} />
        <Route path="/reactTodoTwo" element={<TopicTodoTwo />} />
        <Route path="/reactTodoThree" element={<TopicTodoThree />} />

        <Route path="/reactuse" element={<TopicUseEff />} />
        <Route path="/reactrouter" element={<TopicRouter />} />

        <Route path="/reactredux" element={<TopicRedux />} />

        <Route path="/rapi" element={<Rapi />} />
        <Route path="/rcusthook" element={<Rcusth />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";

const HomePageEF = () => {
  const topics = [
    { id: 1, name: "Introduction to React", path: "/topic/react" },
    { id: 2, name: "JavaScript Basics", path: "/topic/js-basics" },
  ];

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <h1>Learning Platform</h1>
      <h2>Thursday September 19th, 2024</h2>
      <ul>
        <li>
          <Link to="/html">HTML</Link>
        </li>
        <li>
          <Link to="/css">CSS</Link>
        </li>
        <li>
          <Link to="/bootstrap">Bootstrap</Link>
        </li>
      </ul>
      <h2>Tuesday September 24th, 2024</h2>
      <ul>
        <li>
          <Link to="/js">JavaScript</Link>
        </li>
      </ul>
      <h2>Wednesday September 25th, 2024</h2>
      <ul>
        <li>
          <Link to="/spa">SPA Vs MPA</Link>
        </li>
        <li>
          <Link to="/vuejsintro">Introduction to Vue Js</Link>
        </li>
      </ul>
      <h2>Thursday September 26th, 2024</h2>
      <ul>
        <li>
          <Link to="/vuejs">Vue.js (CDN)</Link>
        </li>
      </ul>
      <h2>Tuesday October 1st, 2024</h2>
      <ul>
        <li>
          <Link to="/clisetup">Vue.js CLI setup</Link>
        </li>
        <li>
          <Link to="/vproject">Vue.js CLI Project files</Link>
        </li>
        <li>
          <Link to="/ViewsRoutes">Vue.js Views & Routes</Link>
        </li>
        <li>
          <Link to="/Vcomponents">Vue.js Components</Link>
        </li>
        <li>
          <Link to="/Vprops">Vue.js Props</Link>
        </li>
      </ul>
      <h2>Wednsday October 2nd, 2024</h2>
      <ul>
        <li>
          <Link to="/Vforms">Vue.js Forms</Link>
        </li>
        <li>
          <Link to="/api">Api & intro to server side</Link>
        </li>
      </ul>
      <h2>Thursday October 3rd, 2024</h2>
      <ul>
        <li>
          <b>Mid-term Exam</b>
        </li>
        <li>
          <b>Practice: Finalize Milestone 2</b>
        </li>
      </ul>
      <h2>Tuesday October 8th, 2024</h2>
      <ul>
        <li>
          <Link to="/backend">Backend: Node.js/Express.js</Link>
        </li>
        <li>
          <Link to="/nodemysql">Node.js & Mysql</Link>
        </li>
        <li>
          <Link to="/mcr">Backend example MCR: Model-Controller-Router</Link>
        </li>
        <li>
          <Link to="/projfront">Front-End</Link>
        </li>
      </ul>
      <h2>Wednesday October 9th, 2024</h2>
      <ul>
        <li>
          <Link to="/authback">Authentication: backend</Link>
        </li>
        <li>
          <Link to="/authfront">Authentication: frontend</Link>
        </li>
      </ul>
    </div>
  );
};

export default HomePageEF;

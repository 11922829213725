import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class ReactJsx extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>JSX</h1>
        <h2>What is JSX?</h2>
        <TextExplanation
          text={
            "JSX is a syntax extension to Javascript, it's recommended to use it with React to describe what the UI should look like. JSX may remind you of a template language, but it comes with the full power of JavaScript."
          }
        />
        <TextExplanation
          text={
            "saving you the long process of creating and manipulating the DOM elements"
          }
        />
        <img src="./images/jsx01.png" alt="" />
        <CodeDisplay
          code="const element = <h1>Hello world!</h1>;"
          language="javascript"
        />
        <TextExplanation
          text={"Babel compiles JSX down to React.createElement() calls."}
        />
        For example this code:
        <CodeDisplay
          code="const element = (<h1 className='greeting'>Hello, world!</h1>);"
          language="javascript"
        />
        becomes:
        <CodeDisplay
          code="const element = React.createElement('h1',{className: 'greeting'},'Hello, world!');"
          language="javascript"
        />
        <TextExplanation
          text={
            "please notice: Since JSX is closer to JavaScript than to HTML, React DOM uses camelCase property naming convention instead of HTML attribute names. For example, class becomes className in JSX, and tabindex becomes tabIndex etc.."
          }
        />
        <hr />
        <p>reference: https://react.dev/</p>
      </div>
    );
  }
}

export default ReactJsx;

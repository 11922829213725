import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class Vapi extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>API</h1>
        <h2>application programming interface (API)</h2>
        <TextExplanation
          text={
            "An API is a set of definitions and protocols for building and integrating application software. It’s sometimes referred to as a contract between an information provider and an information user—establishing the content required from the consumer (the call) and the content required by the producer (the response). In other words, if you want to interact with a computer or system to retrieve information or perform a function, an API helps you communicate what you want to that system so it can understand and fulfill the request. "
          }
        />
        <img src="./images/ap01.png" alt="" />
        <h2>What is a REST API?</h2>
        <TextExplanation
          text={"REST is short for representational state transfer. "}
        />
        <TextExplanation
          text={
            "REST is a set of architectural constraints, not a protocol or a standard. API developers can implement REST in a variety of ways.When a client request is made via a RESTful API, it transfers a representation of the state of the resource to the requester or endpoint. This information, or representation, is delivered in one of several formats via HTTP: JSON (Javascript Object Notation), HTML, XLT, Python, PHP, or plain text. JSON is the most generally popular file format to use because, despite its name, it’s language-agnostic, as well as readable by both humans and machines. "
          }
        />
        <h2>Example of a get request from a dummy api</h2>
        <p>https://dummyjson.com/products</p>
        <img src="./images/ap02.png" alt="" />
        <hr />
        <p>reference: https://redhat.com/</p>
      </div>
    );
  }
}

export default Vapi;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class VprojectFiles extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Vue.js Project Folder Structure (Demo)</h1>
        <img src="./images/p01.png" alt="" />
        <img src="./images/p02.png" alt="" />

        <h2 id="direct">main.js</h2>
        <TextExplanation
          text={
            "main.js is importing createApp from Vue using your main parent App.vue to be mounted on #app "
          }
        />
        <h2 id="direct">App.vue</h2>
        <TextExplanation
          text={
            "App.vue is the parent where all your views (pages) will be rendered dynamically using the Vue router in addition to any global components (ex. navbar, footer etc..) "
          }
        />
        <img src="./images/p03.png" alt="" />

        <h2 id="dbind">Static render Demo</h2>
        <img src="./images/p04.png" alt="" />

        <hr />
        <p>reference: https://vuejs.org/</p>
      </div>
    );
  }
}

export default VprojectFiles;

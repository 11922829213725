import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class Rcusth extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>React custom hooks</h1>
        <p>
          Creating a custom hook in React is a great way to encapsulate reusable
          logic and make your components cleaner. Custom hooks allow you to take
          advantage of React's hook features, like useState, useEffect, etc.,
          and reuse them in multiple components.
        </p>
        <h2>Here’s how you can create a simple custom hook in React:</h2>
        <p>
          We're going to create a reusable logic for the fetch method by passing
          a url to our hook and returning the fetched data, loading state, and
          errors if any.
        </p>
        <p>
          the prefix "use" is a naming convention for the react hooks that's why
          we'll call it "useFetch"
        </p>

        <img src="./images/rapi03.png" alt="" />
        <b>And to use our custom hook we need to import it:</b>
        <img src="./images/rapi04.png" alt="" />
        <hr />
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default Rcusth;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class ViewsRoutes extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Vue.js Views and Routes</h1>
        <h2 id="direct">1 - Home page</h2>
        <TextExplanation
          text={
            "Inside Views start by creating your first view that will serve as a home page, containing a template with a basic HTML structure"
          }
        />
        <img src="./images/vr01.png" alt="" />
        <TextExplanation
          text={
            "Inside router > index.js, first import the view for the homepage, then define the route object by specifying the name, path and component"
          }
        />
        <img src="./images/vr02.png" alt="" />
        <h2>Challenge</h2>
        <b>
          create the about page and a global nav bar to switch between home page
          and about page, knowing that for Vue router links: the Hyperlink
        </b>
        <CodeDisplay code='<a href="/about"> About page</a>' language="html" />
        should translate to : router-link
        <CodeDisplay
          code='<router-link to="/about">About page</router-link>'
          language="html"
        />
        <hr />
        <p>reference: https://vuejs.org/</p>
      </div>
    );
  }
}

export default ViewsRoutes;

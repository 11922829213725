import React, { Component } from "react";
import TextExplanation from "./TextExplanation";
import CodeDisplay from "./CodeDisplay";
import { Link } from "react-router-dom";

export class TopicHtml extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>HTML review</h1>
        <h2>Introduction</h2>
        <TextExplanation
          text={
            "HTML is one of the markup languages that was defined using the SGML framework. It simplifies many of SGML's capabilities to make it easier to use for creating web pages. HTML uses a fixed set of tags defined by SGML rules to describe the structure and presentation of web content (such as text, links, images, and multimedia)."
          }
        />
        <TextExplanation
          textspeech={
            "The basic structure of an HTML document consist of: The declaration Doctype html, html, head, and body tags."
          }
          text={
            "The basic structure of an HTML document consist of: The declaration <!DOCTYPE html>, <html>, <head>, and <body> tags."
          }
        />
        <TextExplanation text={"Example of a basic HTML document:"} />
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>HTML Structure</title></head><body><h1>Hello World!</h1></body></html>'
          language="html"
        />
        <h2>HTML Elements</h2>
        <TextExplanation
          textspeech={
            "example of the most used html tags: Titles: h1 to h6. Paragraph: the p tag, Lists: Ordered list, o l, and, Unordered list, u l"
          }
          text={
            <div>
              <p>example of the most used html tags:</p>
              <ul>
                <li>Titles: h1 to h6</li>
                <CodeDisplay
                  code="<h1>Title1</h1><h2>Title2</h2><h3>Title3</h3><h4>Title4</h4><h5>Title4</h5><h6>Title4</h6>"
                  language="html"
                />
                <li>Paragraph: p tag</li>
                <CodeDisplay code="<p>paragraph of text</p>" language="html" />
                <li>Lists: Ordered list ol and Unordered list ul</li>
                <i>Ordered List:</i>
                <CodeDisplay
                  code="<ol><li>Item 1</li><li>Item 2</li><li>Item 3</li></ol>"
                  language="html"
                />
                <i>Unordered List:</i>
                <CodeDisplay
                  code="<ul><li>Item 1</li><li>Item 2</li><li>Item 3</li></ul>"
                  language="html"
                />
                <li>Image: img tag</li>
                The image tag is a self closing tag
                <CodeDisplay
                  code="<img src='/images/image01.jpg' alt='short description of the image' width ='' height = '' />"
                  language="html"
                />
                <li>Hyperlinks: a tag</li>
                There are 3 types of hyperlinks: 1- Internal links, linking to
                another page in the same website
                <CodeDisplay
                  code="<a href='page02.html'>Page 2</a>"
                  language="html"
                />
                2- External links, linking to another website
                <CodeDisplay
                  code="<a href='https://www.google.ca'>Go to Google</a>"
                  language="html"
                />
                3- anchor links, linking to a target in the same page
                <CodeDisplay
                  code="<a href='#thirdParagraph'>Third paragraphe</a>"
                  language="html"
                />
                <li>
                  HTML forms: An HTML form is used to collect user input. The
                  user input is most often sent to a server for processing.
                </li>
                <CodeDisplay
                  code="<form method = 'get' action = 'action_page.php'><label for='fname'>First name:</label><input type='text' id='fname' name='fname' value='John'><label for='lname'>Last name:</label><input type='text' id='lname' name='lname' value='Doe'><input type='submit' value='Submit'>
                  </form>"
                  language="html"
                />
                <a
                  href="https://www.w3schools.com/html/html_form_elements.asp"
                  target="blank"
                >
                  More form elements...
                </a>
                <li>
                  HTML tables: HTML tables allow web developers to arrange data
                  into rows and columns
                </li>
                <CodeDisplay
                  code="<table border='' width=''><thead><th>title1</th><th>title2</th><th>title3</th></thead><tbody><tr><td>data1</td><td>data2</td><td>data3</td></tr></tbody><tfoot><tr><td>footer data1</td><td>footer data2</td><td>footer data3</td></tr></tfoot></table>"
                  language="html"
                />
                <li>
                  <a
                    href="https://www.w3schools.com/html/html5_semantic_elements.asp"
                    target="blank"
                  >
                    Semantic HTML
                  </a>
                </li>
              </ul>
              <hr />
              <p>reference: https://www.w3schools.com</p>
            </div>
          }
        />
      </div>
    );
  }
}

export default TopicHtml;

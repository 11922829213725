import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicAuthBack extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Authentication: Frontend</h1>
        <TextExplanation
          text={
            "The Front end for this example will consist of 3 components: Register, Login and Profile"
          }
        />
        <ol>
          <li>
            <h2>The LoginForm</h2>
            <p>
              a simple form using the two way data binding to send a json object
              containing the username and password
            </p>
            <p>
              the Login form will communicate with the api and display the
              response message
            </p>
            <p>
              if the login is successful we'll receive the jwt token from the
              backend and store it in localStorage to be used to access the
              different sections of the app
            </p>
            <img src="./images/af01.png" alt="" />
          </li>
          <li>
            <h2>The RegisterForm</h2>
            <p>
              a simple form the collect the username and password the send to
              the api endoint to register a new user and display the response
              message
            </p>
            <img src="./images/af02.png" alt="" />
          </li>
          <li>
            <h2>The ProfileComp</h2>
            <p>
              The profile component is sending a get request to the server using
              the stored token in the localStorage.
            </p>
            <p>
              If the authentication is successful it will grant access to the
              user's profile information, otherwise will display access denied
              to the user
            </p>
            <img src="./images/af03.png" alt="" />
          </li>
          <li>
            <h2>The Routes</h2>
            <p>
              The profile component is sending a get request to the server using
              the stored token in the localStorage.
            </p>
            <p>
              If the authentication is successful it will grant access to the
              user's profile information, otherwise will display access denied
              to the user
            </p>
            <img src="./images/af03.png" alt="" />
          </li>
        </ol>

        <hr />
        <p>
          reference: for more advanced state management read about VueX that was
          not implemented in this course: https://vuex.vuejs.org/
        </p>
      </div>
    );
  }
}

export default TopicAuthBack;

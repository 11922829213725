import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class Vcomponents extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Vue.js Components</h1>
        <h2>Create component</h2>
        <TextExplanation
          text={
            "Inside the folder components start creating your first component which will represent a student card. It should always start with the template tag and inside it the HTML structure. The model and logic should be in a script tag, the style for the different elements should be in style tag. In this example I'm providing the students name as an Array and in the template i'm looping to render the name of each students inside a list item."
          }
        />
        <img src="./images/cp01.png" alt="" />
        <h2>Import component</h2>
        <TextExplanation
          text={
            "Now it's time to import the component into the About view, register it and insert it in the template."
          }
        />
        <img src="./images/cp02.png" alt="" />
        <h2>Challenge</h2>
        <b>
          Create a product component that contains an array of objects
          (products) and style a card that will dynamically render each product
          (product name, image and price)
        </b>

        <hr />
        <p>reference: https://vuejs.org/</p>
      </div>
    );
  }
}

export default Vcomponents;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class Vforms extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Vue.js Forms</h1>
        <img src="./images/fr01.png" alt="" />
        <TextExplanation
          textspeech={
            "In this demo we're going to apply different concepts for the forms. 1 - preventing a form default action. 2 - Two ways binding to store user entries into an object. 3 - Nested loops and conditional rendering. 4 - pushing entry in array of objects. "
          }
          text={
            <div>
              <p>
                In this demo we're going to apply different concepts for the
                forms.
              </p>
              <ol>
                <li>preventing a form default action.</li>
                <li>Two ways binding to store user entries into an object.</li>
                <li>Nested loops and conditional rendering.</li>
                <li>pushing entry in array of objects.</li>
              </ol>
            </div>
          }
        />
        <h2>Event modifier</h2>
        <TextExplanation
          text={
            "The default action when you submit an HTML form, will gather the form entries, send it to the server and reload the page, which is an undesirable outcome in our approach for a SPA. In this example we are going to use an event modifier @submit.prevent to prevent the form from its default behaviour and instead it will be calling the action we assign to it "
          }
        />
        <img src="./images/fr02.png" alt="" />
        <h2>Two ways binding to store user entries into an object</h2>
        <TextExplanation
          text={
            "When the user is filling out the form we will be storing the user entries into an object using the v-model two ways data binding "
          }
        />
        <img src="./images/fr03.png" alt="" />
        <h2>Nested loops and conditional rendering</h2>
        <TextExplanation
          text={
            "Notice in this example that the user can select one or more hobbies using the checkboxes. and the hobbies are stored as a nested object inside the student object. Printing the hobbies object directly might not give us the desirable rendering that we're expecting in our app, instead we might need to loop into the nested object the extract the hobbies."
          }
        />
        <img src="./images/fr04.png" alt="" />
        <img src="./images/fr05.png" alt="" />
        <TextExplanation
          text={
            "By looping into the nested object hobbies I was able to extract the values for the different object keys"
          }
        />
        <img src="./images/fr06.png" alt="" />
        <img src="./images/fr07.png" alt="" />
        <TextExplanation
          text={
            "Instead of displaying the key value as an outcome of the loop, I can request the key name as follow:"
          }
        />
        <img src="./images/fr08.png" alt="" />
        <h2>Conditional rendering</h2>
        <TextExplanation
          text={
            "Finally the last issue is that Vue will print all the object keys wether its value is True or False. We could filter this by using the Vue directive v-if to add the condition to render only if true"
          }
        />
        <img src="./images/fr09.png" alt="" />
        <img src="./images/fr10.png" alt="" />
        <h2>
          Pushing the student into the students array, and resetting the input
          fields
        </h2>
        <img src="./images/fr11.png" alt="" />
        <hr />
        <p>reference: https://vuejs.org/</p>
      </div>
    );
  }
}

export default Vforms;

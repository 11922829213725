import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicBackend extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Front-end</h1>
        <b>In this example we'll be exploring the following key points</b>
        <ol>
          <li>conditional rendering</li>
          <li>communicating with API</li>
          <li>pushing router parameters</li>
        </ol>
        <img src="./images/fa01.png" alt="" />
        <h2 id="direct">Project structure</h2>
        <b>This sample project consists of 3 Views:</b>
        <ol>
          <li>src/views/ProductView.vue</li>
          <li>src/views/ProductDetailView.vue</li>
          <li>src/views/NewProductView.vue</li>
        </ol>
        <b>And 2 components:</b>
        <ol>
          <li>src/components/ProductList.vue</li>
          <li>src/components/ProductForm.vue</li>
        </ol>

        <h2>1- Product View</h2>
        <p>The homepage path: '/'</p>
        <p>Using the component ProductList to display all the products</p>
        <CodeDisplay
          code="<template>
  <ProductList />
</template>

<script>
import ProductList from '../components/ProductList.vue';

export default {
  components: {
    ProductList,
  },
};
</script>
"
          language="html"
        />
        <h2>2- ProductList component</h2>
        <CodeDisplay
          code="<template>
  <div>
    <h1>All Products</h1>
    <button @click='$router.push('/new-product')'>Add New Product</button>
    <ul v-if='products.length'>
      <li v-for='product in products' :key='product.id'>
        <h2 @click='viewProduct(product.id)'>{{ product.name }}</h2>
        <p>{{ product.description }}</p>
        <img :src='product.image' alt='Product Image' width='100' />
        <p>Price: ${{ product.price }}</p>
      </li>
    </ul>
    <p v-else>No products found</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],
    };
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await fetch('http://localhost:3000/products');
        const data = await response.json();
        this.products = data;
      } catch (error) {
        console.error(error);
      }
    },
    viewProduct(id) {
      this.$router.push(`/product/${id}`);
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>

"
          language="html"
        />
        <ul>
          <li>
            calling the API on the created() hook to fetch all the products from
            the API
          </li>
          <li>
            Using the directives v-if, v-else to render the list of products if
            found or No products found if none retrived from the API
          </li>
          <li>
            embedding the function viewProduct on each product and passing the
            product id to it{" "}
          </li>
          <li>
            viewProduct function calling the product detailed view while pushing
            a parameter with the product id: this.$router.push(`/product/$id `);
          </li>
        </ul>
        <h2>3- Product detail View</h2>
        <p>
          {" "}
          path: '/product/:id' - the path is defined in the router indicating
          that it's expecting a product id to be passed as parameter
        </p>
        <p>
          Rendering the Product form by passing the received parameter for a
          given product
        </p>
        <CodeDisplay
          code="<template>
  <ProductForm :productId='$route.params.id' />
</template>

<script>
import ProductForm from '../components/ProductForm.vue';

export default {
  components: {
    ProductForm,
  },
};
</script>


"
          language="html"
        />
        <h2>4- Product form component</h2>
        <CodeDisplay
          code="<template>
  <div>
    <h1>{{ isEdit ? 'Edit' : 'New' }} Product</h1>
    <form @submit.prevent='saveProduct'>
      <div>
        <label for='name'>Name:</label>
        <input type='text' v-model='product.name' required />
      </div>
      <div>
        <label for='price'>Price:</label>
        <input type='number' v-model='product.price' required />
      </div>
      <div>
        <label for='description'>Description:</label>
        <textarea v-model='product.description'></textarea>
      </div>
      <div>
        <label for='image'>Image URL:</label>
        <input type='text' v-model='product.image' required />
      </div>
      <button type='submit'>{{ isEdit ? 'Update' : 'Create' }} Product</button>
      <button v-if='isEdit' @click='deleteProduct'>Delete Product</button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    productId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      product: {
        name: '',
        price: '',
        description: '',
        image: '',
      },
      isEdit: !!this.productId,
    };
  },
  methods: {
    async fetchProduct() {
      if (this.isEdit) {
        try {
          const response = await fetch(`http://localhost:3000/products/${this.productId}`);
          const data = await response.json();
          this.product = data;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async saveProduct() {
      try {
        const options = {
          method: this.isEdit ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.product),
        };

        const response = await fetch(
          this.isEdit ? `http://localhost:3000/products/${this.productId}` : 'http://localhost:3000/products',
          options
        );

        if (response.ok) {
          this.$router.push('/');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteProduct() {
      try {
        const response = await fetch(`http://localhost:3000/products/${this.productId}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          this.$router.push('/');
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    this.fetchProduct();
  },
};
</script>

"
          language="html"
        />
        <ul>
          <li>
            using ternary operator to determine if this form is called in edit
            mode or in create new product mode by checking isEdit value which in
            data used with the double !! to return true id an id provided for
            editing or false if no id provided
          </li>
          <li>
            same concept for firing the save product method to call the
            update(PUT) or create(POST)
          </li>
        </ul>
        <h2>5- New Product View</h2>
        <CodeDisplay
          code="<template>
  <ProductForm />
</template>

<script>
import ProductForm from '../components/ProductForm.vue';

export default {
  components: {
    ProductForm,
  },
};
</script>

"
          language="html"
        />
        <p>
          calling the Product form View without parameters to create a new
          product
        </p>
        <hr />
        <p>reference: https://vuejs.org/</p>
      </div>
    );
  }
}

export default TopicBackend;

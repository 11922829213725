import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicBackend extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Backend</h1>
        <ol>
          <li>
            <a href="#backend">What is Backend</a>
          </li>
          <li>
            <a href="#nodejs">Node.js</a>
          </li>
          <li>
            <a href="#express">Express.js</a>
          </li>
        </ol>
        <h2 id="backend">What is a Backend?</h2>
        <TextExplanation
          textspeech={
            "The backend refers to the server-side of a web application. It is responsible for managing data, handling business logic, and serving client requests. The backend typically consists of a server, database, and application logic. It communicates with the frontend (the client-side) to deliver dynamic content and functionalities. Key Responsibilities of a Backend: 1 - Data Storage and Retrieval: Interacting with databases to store, retrieve, and manipulate data.2 - Business Logic: Implementing the rules and processes that govern how data can be created, displayed, and modified.3 - Authentication and Authorization: Managing user sessions and ensuring secure access to resources.4 - API Development: Creating endpoints for the frontend to communicate with the backend."
          }
          text={
            <div>
              <p>
                The backend refers to the server-side of a web application. It
                is responsible for managing data, handling business logic, and
                serving client requests. The backend typically consists of a
                server, database, and application logic. It communicates with
                the frontend (the client-side) to deliver dynamic content and
                functionalities.
              </p>
              <b>Key Responsibilities of a Backend:</b>
              <ol>
                <li>
                  Data Storage and Retrieval: Interacting with databases to
                  store, retrieve, and manipulate data.
                </li>
                <li>
                  Business Logic: Implementing the rules and processes that
                  govern how data can be created, displayed, and modified.
                </li>
                <li>
                  Authentication and Authorization: Managing user sessions and
                  ensuring secure access to resources.
                </li>
                <li>
                  API Development: Creating endpoints for the frontend to
                  communicate with the backend.
                </li>
              </ol>
            </div>
          }
        />
        <h2 id="nodejs">Node.js</h2>
        <TextExplanation
          textspeech={
            "Node.js is a JavaScript runtime built on Chrome's V8 JavaScript engine. It allows developers to use JavaScript for server-side scripting, enabling them to build scalable and high-performance web applications.Example of a Simple Node.js Backend Here's a simple example of a Node.js server using the built-in http module:"
          }
          text={
            <div>
              <p>
                Node.js is a JavaScript runtime built on Chrome's V8 JavaScript
                engine. It allows developers to use JavaScript for server-side
                scripting, enabling them to build scalable and high-performance
                web applications.
              </p>
              <b>Example of a Node.js server using the built-in http module:</b>
            </div>
          }
        />
        <p>First, create your project folder</p>
        <p>Run the terminal at your project folder</p>
        <p>initialize node package manager by running the command:</p>
        <CodeDisplay code="npm init" language="javascript" />
        <p>create the server.js app file</p>
        <CodeDisplay
          code="const http = require('http');

const server = http.createServer((req, res) => {
    res.statusCode = 200; // HTTP status code
    res.setHeader('Content-Type', 'text/plain'); // Response type
    res.end('Hello, World!'); // Response body
});

server.listen(3000, () => {
    console.log('Server running at http://localhost:3000/');
});
"
          language="javascript"
        />
        <p>run the server using the command:</p>
        <CodeDisplay code="node server.js" language="javascript" />
        <h2 id="express">What is Express.js?</h2>
        <TextExplanation
          textspeech={
            "Express.js is a minimalist web framework for Node.js that simplifies the process of building web applications. It provides a robust set of features to develop web and mobile applications, including routing, middleware support, and template rendering. Key Features of Express.js: Routing: Easy definition of routes for handling different HTTP requests. Middleware: Ability to add functionalities at different points in the request-response cycle. Error Handling: Built-in error handling capabilities. Example of a simple Express.js application that responds to different routes:"
          }
          text={
            <div>
              <p>
                Express.js is a minimalist web framework for Node.js that
                simplifies the process of building web applications. It provides
                a robust set of features to develop web and mobile applications,
                including routing, middleware support, and template rendering.
              </p>
              <b>Key Features of Express.js:</b>
              <ol>
                <li>
                  Routing: Easy definition of routes for handling different HTTP
                  requests.
                </li>
                <li>
                  Middleware: Ability to add functionalities at different points
                  in the request-response cycle.
                </li>
                <li>Error Handling: Built-in error handling capabilities.</li>
              </ol>
            </div>
          }
        />
        <b>
          a simple Express.js application that responds to different routes:
        </b>
        <p>install express by running the command:</p>
        <CodeDisplay code="npm install express" language="javascript" />
        <CodeDisplay
          code="const express = require('express');
const app = express();
const port = 3000;

// Route for the homepage
app.get('/', (req, res) => {
    res.send('Welcome to the Express.js application!');
});

// Route for another page
app.get('/about', (req, res) => {
    res.send('This is the about page.');
});

// Start the server
app.listen(port, () => {
    console.log(`Server running at http://localhost:${port}/`);
});
"
          language="javascript"
        />
        In this example:
        <p>
          We create an Express application that listens for HTTP GET requests on
          two routes: / and /about.
        </p>
        <p>
          When a user accesses the root URL, they receive a welcome message, and
          when they access the /about route, they see a different message.
        </p>
        <hr />
        <p>reference: https://nodejs.org/docs/latest/api/</p>
      </div>
    );
  }
}

export default TopicBackend;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class ReactIntro extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>React.js</h1>
        <h2>Introduction</h2>
        <ul>
          <li>React is a javascript front-end framework created by Facebook</li>
          <li>
            React is capable of creating the most complex apps by breaking it
            down into small pieces called components that are efficient and
            reusable
          </li>
          <li>React is used to create Single page applications</li>
          <li>
            Instead of directly manipulating the DOM, React creates a virtual
            DOM that receives the modifications and update only the parts that
            needs to be updated in the DOM
          </li>
        </ul>
        <h2>Using the React library via CDN (Legacy)</h2>

        <CodeDisplay
          code='<script crossorigin src="https://unpkg.com/react@18/umd/react.development.js"></script>'
          language="javascript"
        />
        <CodeDisplay
          code='<script crossorigin src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"></script>'
          language="javascript"
        />
        <h2>Babel library for JSX</h2>
        <CodeDisplay
          code='<script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>'
          language="javascript"
        />
        <b>
          This is a quick introduction to demonstrate how to use React via CDN
          but it's not advised to use it for production, instead we will move
          quickly to React CLI via npm
        </b>
        <CodeDisplay
          code='<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>React via CDN</title>

    <script
      crossorigin
      src="https://unpkg.com/react@18/umd/react.development.js"
    ></script>
    <script
      crossorigin
      src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"
    ></script>

    <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>
  </head>
  <body>
    <div id="myapp"></div>
    <script type="text/babel">
      const elem = <h1>My name is Fadi</h1>;
      ReactDOM.render(elem, document.getElementById("myapp"));
    </script>
  </body>
</html>'
          language="html"
        />
        <b>function returning JSX</b>
        <CodeDisplay
          code='<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>React via CDN</title>

    <script
      crossorigin
      src="https://unpkg.com/react@18/umd/react.development.js"
    ></script>
    <script
      crossorigin
      src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"
    ></script>

    <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>
  </head>
  <body>
    <div id="myapp"></div>
    <script type="text/babel">
   
      function HelloStudents() {
        return <h1>Hello from react via CDN </h1>;
      }
      ReactDOM.render(<HelloStudents />, document.getElementById("myapp"));
    </script>
  </body>
</html>'
          language="html"
        />
        <b>
          With the above example we have created our first react functional
          component.
        </b>
        <CodeDisplay
          code='<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>React via CDN</title>
 <body>
    <div id="myapp"></div>
    <script
      crossorigin
      src="https://unpkg.com/react@18/umd/react.development.js"
    ></script>
  <script type="text/babel">
        function ItemList() {
            const items = ["Apple", "Banana", "Cherry", "Date", "Elderberry"];

            return (
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            );
        }

        ReactDOM.render(<ItemList />, document.getElementById("root"));
    </script>
  </body>
</html>'
          language="html"
        />
        <hr />
        <p>reference: https://react.dev/</p>
      </div>
    );
  }
}

export default ReactIntro;

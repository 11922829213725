import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicMCR extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>MCR: Model-Controller-Router</h1>

        <TextExplanation
          text={
            "The architecture used in this example follows a Model-Controller-Router (MCR) pattern, which is commonly employed in web application backends. It loosely aligns with the Model-View-Controller (MVC) pattern, but without the 'View' part since it's a backend-only API without rendering a frontend. Here's the breakdown:"
          }
        />
        <img src="./images/bk01.png" alt="" />
        <ol>
          <li>
            <b>Model (models/productModel.js)</b>
            <ul>
              <li>
                The model is responsible for interacting with the database. It
                abstracts database operations and provides methods for
                accessing, updating, creating, and deleting data. In this case,
                the Product model interacts with the MySQL database using the
                mysql2 library.
              </li>
              <b>Responsibilities:</b>
              <li>Querying the database for products.</li>
              <li>
                Defining CRUD operations (Create, Read, Update, Delete) that
                directly manipulate the products table.
              </li>
              <li>
                This layer hides the database implementation details from the
                rest of the app.
              </li>
            </ul>
            <img src="./images/bk02.png" alt="" />
          </li>
          <li>
            Controller Logic (In the routes/productRoutes.js)
            <ul>
              <li>
                The controller logic is embedded in the routes. It is
                responsible for handling business logic and coordinating the
                interaction between the routes (client requests) and the model
                (database).
              </li>
              <b>Responsibilities:</b>
              <li>Receiving and interpreting HTTP requests.</li>
              <li>
                Invoking the appropriate model method (e.g., getAll, getById,
                create, update, delete).
              </li>
              <li>
                Handling errors and returning proper HTTP responses (status
                codes and messages).
              </li>
            </ul>
            <img src="./images/bk03.png" alt="" />
          </li>
          <li>
            Router (routes/productRoutes.js)
            <ul>
              <li>
                The router defines the API endpoints and maps them to the
                corresponding controller logic.
              </li>
              <b>Responsibilities:</b>
              <li>
                Route the incoming HTTP requests (e.g., GET, POST, PUT, DELETE)
                to the appropriate function.
              </li>
              <li>
                For example, a GET request to /products/:id is routed to the
                function that fetches a product by its ID.
              </li>
              <li>
                It provides a clean separation between the URL routing and the
                actual logic that handles the requests.
              </li>
            </ul>
          </li>
        </ol>
        <img src="./images/bk04.png" alt="" />
        <hr />
        <p>reference: https://nodejs.org/docs/latest/api/</p>
      </div>
    );
  }
}

export default TopicMCR;

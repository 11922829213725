import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class Rapi extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>API</h1>
        <h2>application programming interface (API)</h2>
        <TextExplanation
          text={
            "An API is a set of definitions and protocols for building and integrating application software. It’s sometimes referred to as a contract between an information provider and an information user—establishing the content required from the consumer (the call) and the content required by the producer (the response). In other words, if you want to interact with a computer or system to retrieve information or perform a function, an API helps you communicate what you want to that system so it can understand and fulfill the request. "
          }
        />
        <img src="./images/ap01.png" alt="" />
        <h2>What is a REST API?</h2>
        <TextExplanation
          text={"REST is short for representational state transfer. "}
        />
        <TextExplanation
          text={
            "REST is a set of architectural constraints, not a protocol or a standard. API developers can implement REST in a variety of ways.When a client request is made via a RESTful API, it transfers a representation of the state of the resource to the requester or endpoint. This information, or representation, is delivered in one of several formats via HTTP: JSON (Javascript Object Notation), HTML, XLT, Python, PHP, or plain text. JSON is the most generally popular file format to use because, despite its name, it’s language-agnostic, as well as readable by both humans and machines. "
          }
        />
        <h2>Example of a get request from a dummy api</h2>
        <ul>
          <li>
            in this example we're requesting all the posts from the api endpoint
          </li>
          <li>
            using the javascript 'fetch' method we'll send our request to the
            api
          </li>
          <li>
            the api will send receive our request and communicate with the
            backend which will query the database for this request and respond
            with the result then return it to the api in json format
          </li>
          <li>the api will send the response back to the front end</li>
        </ul>

        <p>
          <b>endpoint: </b>https://jsonplaceholder.typicode.com/posts
        </p>
        <img src="./images/rapi01.png" alt="" />
        <h2>Async function</h2>
        <p>
          In JavaScript, using async functions provides a clean and efficient
          way to handle asynchronous operations, such as fetching data from APIs
          or reading files, without blocking the main execution flow. Here are
          some key reasons to use async functions:
        </p>
        <ol>
          <li>
            <b>Simplifies Asynchronous Code:</b> async functions allow you to
            write asynchronous code that looks and behaves like synchronous
            code, making it easier to read and maintain. By combining async with
            await, you avoid using complex callback chains or .then() chains
            with promises.
          </li>
          <li>
            <b>Error Handling:</b> async/await syntax makes error handling more
            straightforward with try...catch blocks. Errors thrown in an async
            function are caught in the catch block, making it easier to debug.
          </li>
          <li>
            <b>Non-blocking Execution:</b> Asynchronous functions let JavaScript
            perform other tasks while waiting for a promise to resolve, which is
            essential in applications needing responsiveness, like web apps.
            Without async, long-running tasks could block the main thread.
          </li>
          <li>
            <b>Code Flow Control:</b> await pauses the execution inside an async
            function until the awaited promise resolves. This allows you to
            control the flow of operations that need to happen sequentially,
            such as waiting for data to load before displaying it.
          </li>
        </ol>
        <b>Example:</b>
        <CodeDisplay
          code="async function fetchData() {
  try {
    let response = await fetch('https://api.example.com/data');
    let data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
"
          language="javascript"
        />
        <b>To appy this concept to our example:</b>
        <img src="./images/rapi02.png" alt="" />
        <hr />
        <p>reference: https://redhat.com/</p>
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default Rapi;

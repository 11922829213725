import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicBackend extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Node.js & Mysql</h1>
        <TextExplanation
          text={
            "To use MySQL with Node.js, the mysql2 package is a popular choice. It is a lightweight and performant library for interacting with MySQL databases, supporting both callback and promise-based APIs."
          }
        />
        <p>Install MYSQL2 package via npm by running the following command</p>
        <CodeDisplay code="npm install mysql2" language="javascript" />
        <p>
          Create a MySQL Database: Before interacting with MySQL from Node.js,
          ensure you have a running MySQL instance and a database with a table
          to work with. For example, let’s assume you have the following MySQL
          table:
        </p>
        <CodeDisplay
          code="-- Create the database
CREATE DATABASE IF NOT EXISTS products_db;

-- Use the newly created database
USE products_db;

-- Create the products table
CREATE TABLE IF NOT EXISTS products (
    id INT AUTO_INCREMENT PRIMARY KEY,
    name VARCHAR(255) NOT NULL,
    price DECIMAL(10, 2) NOT NULL,
    description TEXT,
    image VARCHAR(255) NOT NULL
);"
          language="sql"
        />
        <p>create the server.js app file</p>
        <CodeDisplay
          code='const mysql = require("mysql2");

const connection = mysql.createConnection({
  host: "localhost",
  user: "root", // Your MySQL username
  password: "fadi1234", // Your MySQL password
  database: "products_db", // Database name
});

connection.connect((err) => {
  if (err) {
    console.error("Error connecting to MySQL database: ", err);
    return;
  }
  console.log("Connected to MySQL database");
});

function getAllProducts(callback) {
  connection.query("SELECT * FROM products", (err, results) => {
    if (err) {
      console.error("Error executing query: ", err);
      return;
    }
    callback(results); // Pass the results to the callback
  });
}

getAllProducts((results) => {
  console.log(results); // Handle the results when the query completes
});

function getProduct(id, callback) {
  connection.query(
    `SELECT * FROM products WHERE id = ?`,
    [id],
    (err, results) => {
      if (err) {
        console.error("Error executing query: ", err);
        return;
      }
      callback(results); // Pass the results to the callback
    }
  );
}

getProduct(3, (results) => {
  console.log("Product with id:3 ", results); // Handle the results when the query completes
});

'
          language="javascript"
        />
        <p>run the server using the command:</p>
        <CodeDisplay code="node server.js" language="javascript" />
        <h2>In this example:</h2>
        <ol>
          <li>
            <b>Creating a Connection:</b> The mysql.createConnection() method
            establishes a connection to the MySQL server by specifying
            parameters like host, user, password, and database.
          </li>
          <li>
            <b>Connecting to the Database:</b> The connection.connect() method
            actually opens the connection. If there’s an error during the
            connection, it is logged.
          </li>
          <li>
            <b>Querying the Database:</b> The connection.query() method executes
            an SQL query. In this case, we are retrieving all rows from the
            users table. The results are returned in a callback and logged to
            the console.
          </li>
          <li>
            <b>Closing the Connection:</b> You could use connection.end() method
            closes the connection when all operations are complete to avoid
            leaving open connections to the database.
          </li>
        </ol>
        <hr />
        <p>reference: https://nodejs.org/docs/latest/api/</p>
      </div>
    );
  }
}

export default TopicBackend;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicTodoOne extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>Creating a simple todo list</h1>
        <ol>
          <li>
            <h2>Dealing with form inputs</h2>
            <p>In this example we'll be creating a simple to do list</p>
            <p>
              starting with 2 states first one for the todos and second one to
              retrieve the input field value
            </p>
            <p>
              Using a simple form we'll call the function to handleAdd by
              receiving an event target value and set the input value to the
              received and using a timestamp as an id for this todo
            </p>
            <p>
              Examine the code and notice that: the default behaviour of
              submitting an html form is to communicate with a server and then
              refresh the page, which we really don't want in the case of
              creating a SPA. So the first thing we do is to prevent this form
              from its default behaviour by using .preventDefault() on
              submitting the form.
            </p>

            <CodeDisplay
              code='import React, { useState } from "react";

const App = () => {
  const [todos, setTodos] = useState([]);  // Store tasks
  const [input, setInput] = useState("");  // Track input value

  const handleAdd = (e) => {
    e.preventDefault();

    const newTodo = { id: Date.now(), text: input };
    setTodos([...todos, newTodo]);
    setInput("""");  // Clear input field
  };

  return (
    <div className="App">
      <h1>To-Do List</h1>
      <form onSubmit={handleAdd}>
        <input
          type="text"
          placeholder="Add a task..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button type="submit">Add</button>
      </form>
      <ul>
        {todos.map((todo) => (
          <li key={todo.id}>{todo.text}</li>
        ))}
      </ul>
    </div>
  );
};

export default App;
'
              language="javascript"
            />
          </li>
        </ol>

        <hr />
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default TopicTodoOne;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicRouter extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>React Router</h1>
        <p>
          React Router is a popular library used for managing navigation and
          routing in React applications. It allows you to build single-page
          applications (SPAs) where the user can navigate between different
          views (pages) without the need to refresh the entire page.
        </p>
        <p>
          Instead of the traditional multi-page setup, React Router maps
          different URLs to React components, giving the feel of multiple pages
          while maintaining fast, dynamic content updates.
        </p>
        <ol>
          <li>
            <h2>Set up a React Project</h2>
            <CodeDisplay
              code="npx create-react-app react-router-demo"
              language="sh"
            />
          </li>
          <li>
            <h2>Install React Router</h2>
            <CodeDisplay code="npm install react-router-dom" language="sh" />
          </li>
          <li>
            <h2>Basic Folder Structure</h2>
            <p>In the src folder create the folder: pages</p>
            <p>
              inside the pages folder create Home.jsx (returning an h1 saying
              Welcome to Home page), About.jsx (returning an h1 saying About
              Us), Contact.jsx (returning an h1 saying Contact Us)
            </p>
          </li>
          <li>
            <h2>Set Up React Router in App.js</h2>
            <img src="./images/rr01.png" alt="" />
            <ul>
              <li>
                <b>BrowserRouter (Router):</b> Wraps the app to enable routing
                functionality.
              </li>
              <li>
                <b>Routes:</b> Holds multiple Route components.
              </li>
              <li>
                <b>Route:</b> Defines the path and which component should be
                rendered for that path.
              </li>
              <li>
                <b>Link:</b> Used to create navigation links without reloading
                the page.
              </li>
            </ul>
          </li>
        </ol>
        <h1>Activity (For Assignment 03)</h1>
        <p>
          Recreate this example while adding buttons to choose language:
          Francais or English
        </p>
        <p>
          if the user choose French, the pages content should be displayed in
          french
        </p>
        <ul>
          <li>Home: 'Bienvenue à la page d'Acceuil'</li>
          <li>About: 'A propos de Nous'</li>
          <li>Contact: 'Nous joindre'</li>
        </ul>
        <p>Tip: use props to pass the value for the chosen language</p>
        <hr />
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default TopicRouter;

import React, { Component } from "react";
import TextExplanation from "./TextExplanation";
import CodeDisplay from "./CodeDisplay";
import { Link } from "react-router-dom";

export class TopicJsRe extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>JavaScript review</h1>
        <ol>
          <li>
            <a href="#intro">Introduction</a>
          </li>
          <li>
            <a href="#variables">Variables</a>
          </li>
          <li>
            <a href="#ternary">Ternary operator</a>
          </li>
          <li>
            <a href="#arrow">Arrow function</a>
          </li>
          <li>
            <a href="#arrays">Arrays</a>
          </li>
          <li>
            <a href="#classes">Classes</a>
          </li>
          <li>
            <a href="#modular">Modular JavaScript</a>
          </li>
        </ol>
        <h2 id="intro">Introduction</h2>
        <TextExplanation
          text={
            "JavaScript ES6 (ECMAScript 2015) introduced several powerful features that modernized the language, making it more efficient and easier to work with. Key additions include let and const for block-scoped variables, arrow functions for cleaner syntax, and classes for object-oriented programming. These enhancements have improved the readability, performance, and versatility of JavaScript code."
          }
        />
        <h2 id="variables">Variables declaration (var, let, const)</h2>
        <TextExplanation
          text={
            "In JavaScript, var, let, and const are used to declare variables, but they differ in scope and mutability:"
          }
        />
        <ul>
          <li>
            <b>var:</b> Has function scope and is hoisted, meaning it's
            accessible before its declaration, which can lead to unexpected
            behavior. Example:
            <CodeDisplay
              code="var x = 10;if (true) {var x = 20;}console.log(x); // 20 (redeclares in the same scope)"
              language="javascript"
            />
          </li>
          <li>
            <b>let (ES6):</b> Has block scope, so it's limited to the block
            where it's declared. It's not hoisted like var, which avoids many
            bugs. Example:
            <CodeDisplay
              code="let y = 10;if (true) {let y = 20;}console.log(y); // 10 (block-scoped)"
              language="javascript"
            />
          </li>
          <li>
            <b>const (ES6):</b> Also block-scoped but used for constants,
            meaning the value cannot be reassigned. Example:
            <CodeDisplay
              code="const z = 30;
              // z = 40; 
              // Error: Assignment to constant variable"
              language="javascript"
            />
          </li>
        </ul>
        <b>Key Difference Between let and var: </b>let respects block scope and
        prevents accidental redeclaration, while var does not, often leading to
        scope-related issues in larger codebases.
        <h2 id="ternary">Ternary operator</h2>
        <TextExplanation
          text={
            "The ternary operator in JavaScript is a shorthand for an if-else statement, used to evaluate a condition and return one of two values based on whether the condition is true or false. Its syntax is:"
          }
        />
        <CodeDisplay
          code="condition ? expressionIfTrue : expressionIfFalse;"
          language="javascript"
        />
        <b>Example:</b>
        <CodeDisplay
          code="let age = 18;let access = (age >= 18) ? 'Granted' : 'Denied';console.log(access); // Output: Granted"
          language="javascript"
        />
        <TextExplanation
          text={
            "In this example, if age is 18 or greater, the value 'Granted' is assigned to access, otherwise, it's 'Denied'. The ternary operator provides a concise way to handle simple conditional logic."
          }
        />
        <h2 id="arrow">Arrow function</h2>
        <TextExplanation
          text={
            "An arrow function is a concise way to write function expressions in JavaScript, introduced in ES6. It provides a shorter syntax compared to traditional function expressions and automatically binds the this value to the surrounding context, which is particularly useful in certain scenarios."
          }
        />
        <CodeDisplay
          code="// Traditional function expression
const add = function(a, b) {
  return a + b;
};

// Arrow function equivalent
const addArrow = (a, b) => {
  return a + b;
};

// Shorter syntax for single expression (implicit return)
const addShort = (a, b) => a + b;

console.log(add(2, 3));        // Output: 5
console.log(addArrow(2, 3));   // Output: 5
console.log(addShort(2, 3));   // Output: 5
"
          language="javascript"
        />
        <h2 id="arrays">Arrays</h2>
        <TextExplanation
          text={
            "Arrays are used to store multiple values in a single variable."
          }
        />
        <ul>
          <li>
            <b>Simple array</b>
            <TextExplanation
              text={
                "Arrays are used to store multiple values in a single variable."
              }
            />
            <CodeDisplay
              code="let fruits = ['apple', 'banana', 'cherry'];console.log(fruits[1]); // Output: banana"
              language="javascript"
            />
          </li>
          <li>
            <b>Object Array</b>
            <TextExplanation
              text={"An array where each element is an object."}
            />
            <CodeDisplay
              code="let users = [{ name: 'Alice', age: 25 },{ name: 'Bob', age: 30 }];console.log(users[1].name); // Output: Bob"
              language="javascript"
            />
          </li>
          <li>
            <b>Basic Object Destructuring</b>
            <TextExplanation
              text={
                "Destructuring object in JavaScript allows you to extract specific properties into distinct variables. This is useful when you only need certain values from an object."
              }
            />
            <CodeDisplay
              code="const user = {name: 'Alice',age: 25,city: 'New York'};const { name, age } = user;console.log(name); // Output: Aliceconsole.log(age);  // Output: 25"
              language="javascript"
            />
          </li>
          <li>
            <b>Spreading Arrays</b>
            <TextExplanation
              text={
                "The spread operator (...) allows you to expand elements of an array into individual elements."
              }
            />
            <CodeDisplay
              code="let arr1 = [1, 2, 3];let arr2 = [...arr1, 4, 5];console.log(arr2); // Output: [1, 2, 3, 4, 5]"
              language="javascript"
            />
          </li>
          <li>
            <b>Mapping an Array of Objects</b>
            <TextExplanation
              text={
                "The map() method can be used to iterate over an array of objects, allowing you to transform each object into a different form or extract certain properties."
              }
            />
            <CodeDisplay
              code="let users = [{ name: 'Alice', age: 25 },{ name: 'Bob', age: 30 },{ name: 'Charlie', age: 35 }];let userNames = users.map(user => user.name);console.log(userNames); // Output: ['Alice', 'Bob', 'Charlie']"
              language="javascript"
            />
          </li>
          <li>
            <b>Filter an Array</b>
            <TextExplanation
              text={
                "filter() creates a new array with elements that pass a test provided by a function."
              }
            />
            <CodeDisplay
              code="let numbers = [1, 2, 3, 4, 5];let evenNumbers = numbers.filter(num => num % 2 === 0);console.log(evenNumbers); // Output: [2, 4]"
              language="javascript"
            />
          </li>
        </ul>
        <h2 id="classes">Classes</h2>
        <TextExplanation
          text={
            "In JavaScript, classes (introduced in ES6) are a way to create objects using a more intuitive, object-oriented syntax. Classes encapsulate data (properties) and methods (functions) in a blueprint for creating objects."
          }
        />
        <CodeDisplay
          code="class Person {
  constructor(name, age) {
    this.name = name;
    this.age = age;
  }

  greet() {
    console.log(`Hello, my name is ${this.name} and I'm ${this.age} years old.`);
  }
}

const person1 = new Person('Alice', 25);
person1.greet(); // Output: Hello, my name is Alice and I'm 25 years old.
"
          language="javascript"
        />
        <h2 id="modular">Modular JavaScript</h2>
        <TextExplanation
          text={
            "In a modular JavaScript structure, you can split your classes into different files and use export and import to manage them."
          }
        />
        <b>person.js (Module with Class)</b>
        <CodeDisplay
          code="export class Person {
  constructor(name, age) {
    this.name = name;
    this.age = age;
  }

  greet() {
    console.log(`Hello, my name is ${this.name} and I'm ${this.age} years old.`);
  }
}
"
          language="javascript"
        />
        <b>main.js (Importing and Using the Class)</b>
        <CodeDisplay
          code="import { Person } from './person.js';

const person1 = new Person('Bob', 30);
person1.greet(); // Output: Hello, my name is Bob and I'm 30 years old.

"
          language="javascript"
        />
        <hr />
        <p>
          reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript
        </p>
        <p>reference: https://www.w3schools.com/js/default.asp</p>
      </div>
    );
  }
}

export default TopicJsRe;

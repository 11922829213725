import React, { Component } from "react";
import TextExplanation from "./TextExplanation";
import CodeDisplay from "./CodeDisplay";
import { Link } from "react-router-dom";

export class TopicBS extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>Bootstrap 5 review</h1>
        <h2>Introduction</h2>
        <TextExplanation
          text={
            "Bootstrap 5 is the newest version of Bootstrap, which is the most popular HTML, CSS, and JavaScript framework for creating responsive, mobile-first websites. Bootstrap 5 is completely free to download and use!"
          }
        />
        <h2>Using B5 in your website:</h2>
        <TextExplanation
          textspeech={
            "You can download a starter template for Bootstrap, or, You can link Bootstrap to the head of your page via bootstrap CDN, or, Install Bootstrap via package manager"
          }
          text={
            "You can download a starter template for Bootstrap, or, You can link Bootstrap to the head of your page via bootstrap CDN, or, Install Bootstrap via package manager"
          }
        />
        <a href="https://getbootstrap.com" target="blank">
          Bootstrape official website
        </a>
        <h2>Bootstrap 5 Simple Components (Demo)</h2>
        <ul>
          <li>
            <a
              href="https://www.w3schools.com/bootstrap5/bootstrap_colors.php"
              target="blank"
            >
              Colors
            </a>
          </li>
          <li>
            <a
              href="https://www.w3schools.com/bootstrap5/bootstrap_typography.php"
              target="blank"
            >
              Typography
            </a>
          </li>
          <li>
            <a
              href="https://www.w3schools.com/bootstrap5/bootstrap_buttons.php"
              target="blank"
            >
              Buttons
            </a>
          </li>
          <li>
            <a
              href="https://www.w3schools.com/bootstrap5/bootstrap_images.php"
              target="blank"
            >
              Images
            </a>
          </li>
          <li>
            <a
              href="https://www.w3schools.com/bootstrap5/bootstrap_containers.php"
              target="blank"
            >
              Containers
            </a>
          </li>
        </ul>
        <h2>Bootstrap 5 Grid System (Demo)</h2>
        <a
          href="https://www.w3schools.com/bootstrap5/bootstrap_grid_basic.php"
          target="blank"
        >
          Grid System
        </a>
        <h2>Bootstrap 5 Structured Components</h2>
        <ul>
          <li>
            <a
              href="https://www.w3schools.com/bootstrap5/bootstrap_navbar.php"
              target="blank"
            >
              Navigation bar
            </a>
          </li>
          <li>
            <a
              href="https://www.w3schools.com/bootstrap5/bootstrap_carousel.php"
              target="blank"
            >
              Carousel
            </a>
          </li>
        </ul>

        <hr />
        <p>reference: https://www.w3schools.com/bootstrap5/index.php</p>
        <p>reference: https://getbootstrap.com/</p>
      </div>
    );
  }
}

export default TopicBS;

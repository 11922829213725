import React, { Component } from "react";
import TextExplanation from "./TextExplanation";
import CodeDisplay from "./CodeDisplay";
import { Link } from "react-router-dom";

export class TopicSpa extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>SPA vs MPA</h1>
        <h2 id="intro">Introduction</h2>
        <TextExplanation
          textspeech={
            "Single Page Application (SPA): Loads a single HTML page. JavaScript dynamically updates the content without reloading the whole page. Improves speed and user experience as only data is fetched, not the entire page.Multiple Page Application (MPA):Each click on a link loads a new HTML page. A full reload occurs for every navigation, sending a new request to the server for each page."
          }
          text={
            <div>
              <b>Single Page Application (SPA):</b>
              <ul>
                <li>Loads a single HTML page.</li>
                <li>
                  JavaScript dynamically updates the content without reloading
                  the whole page.
                </li>
                <li>
                  Improves speed and user experience as only data is fetched,
                  not the entire page.
                </li>
              </ul>
              <b>Multiple Page Application (MPA):</b>
              <ul>
                <li>Each click on a link loads a new HTML page.</li>
                <li>
                  A full reload occurs for every navigation, sending a new
                  request to the server for each page.
                </li>
              </ul>
            </div>
          }
        />
        <h2>Example MPA</h2>
        <CodeDisplay
          code='<!-- index.html -->
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Multiple Page Application</title>
</head>
<body>
    <h1>Home Page</h1>
    <a href="about.html">Go to About</a>
</body>
</html>
'
          language="html"
        />
        <CodeDisplay
          code='<!-- about.html -->
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>About Page</title>
</head>
<body>
    <h1>About Page</h1>
    <a href="index.html">Go to Home</a>
</body>
</html>

'
          language="html"
        />
        <h2>Example SPA</h2>
        <CodeDisplay
          code='<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Single Page Application</title>
</head>
<body>
    <div id="content">
        <h1>Home Page</h1>
        <a href="#" onclick="loadAbout()">Go to About</a>
    </div>

    <script>
        function loadAbout() {
            document.getElementById("content").innerHTML = `
                <h1>About Page</h1>
                <a href="#" onclick="loadHome()">Go to Home</a>
            `;
        }

        function loadHome() {
            document.getElementById("content").innerHTML = `
                <h1>Home Page</h1>
                <a href="#" onclick="loadAbout()">Go to About</a>
            `;
        }
    </script>
</body>
</html>


'
          language="html"
        />
      </div>
    );
  }
}

export default TopicSpa;

import React, { Component } from "react";
import TextExplanation from "./TextExplanation";
import CodeDisplay from "./CodeDisplay";
import { Link } from "react-router-dom";

export class TopicCSS extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/EF"> Back </Link>
        <h1>CSS review</h1>
        <h2>Introduction</h2>
        <TextExplanation
          text={
            "CSS is the language we use to style an HTML document. CSS describes how HTML elements should be displayed."
          }
        />
        <TextExplanation
          textspeech={
            "Types of CSS: 1 Inline css. 2 Internal css. 3 External css."
          }
          text={""}
        />
        <h2>Types of CSS:</h2>

        <ol>
          <li>Inline css</li>
          <li>Internal css</li>
          <li>External css</li>
        </ol>
        <TextExplanation
          text={
            "1 - Inline css is defined using the style attribute that goes inside the opening tag of the html element. in the value of the style attribute you list the css properties with its respective value, separate the different css properties with a semi-column ;"
          }
        />
        <CodeDisplay
          code='<p style="width:300px;height:300px">Lorem Ipsum...</p>'
          language="html"
        />
        <TextExplanation
          text={
            "2 - Internal css is defined within a style tag in the head of the document and calling the elements using the css selectors to apply a style. you can select the elements by tag name, by ID or by class name"
          }
        />
        <CodeDisplay
          code="<head><style>p{color: blue;}</style></head>"
          language="html"
        />
        <TextExplanation
          text={
            "3 - External css linked to an external style sheet defined in the document head tag and calling the elements using the css selectors to apply a style. you can select the elements by tag name, by ID or by class name"
          }
        />
        <CodeDisplay
          code="<head><link rel='stylesheet' href='style.css'></head>"
          language="html"
        />
        <h2>In-class demo</h2>
        <ul>
          <li>CSS box model</li>
          <li>box sizing property</li>
          <li>CSS display property</li>
          <li>Flexbox</li>
          <li>web fonts ( Google fonts)</li>
        </ul>
        <hr />
        <p>reference: https://www.w3schools.com/css/default.asp</p>
      </div>
    );
  }
}

export default TopicCSS;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicUseEff extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>The useEffect hook</h1>
        <ol>
          <li>
            <b>
              The useEffect hook in React is used to perform side effects in
              functional components. Side effects can include tasks like:
            </b>
            <ul>
              <li>Fetching data from an API</li>
              <li>Setting up subscriptions (e.g., WebSocket)</li>
              <li>Interacting with the DOM (e.g., setting document titles)</li>
              <li>Cleaning up resources (e.g., removing event listeners)</li>
              <p>
                useEffect helps run this logic at specific points in a
                component's lifecycle. It is a combination of componentDidMount,
                componentDidUpdate, and componentWillUnmount from class
                components.
              </p>
              <li>
                <b>Syntax:</b>
                <img src="./images/usf01.png" alt="" />
                <ul>
                  <li>
                    <b>Effect Function: </b>Runs the code (side effect).
                  </li>
                  <li>
                    <b>Cleanup Function: </b>Optional, runs when the component
                    unmounts or before re-running the effect.
                  </li>
                  <li>
                    <b>Dependencies Array:</b> Determines when the effect should
                    re-run. If empty ([]), the effect runs only once (on mount).
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h2>Example: Running Once on Mount </h2>
            <img src="./images/usf02.png" alt="" />
          </li>
          <li>
            <h2>Example: With dependencies (on Update) </h2>
            <img src="./images/usf03.png" alt="" />
            <p>correction* should import useState as well from react</p>
          </li>
          <li>
            <h2>Example: Cleanup (on Unmount)</h2>
            <img src="./images/usf04.png" alt="" />
            <ul>
              <b>Behavior:</b>
              <li>On window resize, the width updates.</li>
              <li>
                The event listener is removed when the component unmounts to
                prevent memory leaks.
              </li>
            </ul>
          </li>
        </ol>
        <h1>Activities (Assignement 03)</h1>
        <ol>
          <li>
            <h2>Document Title Update</h2>
            <ul>
              <b>Goal: Update the document title with the current count.</b>
              <li>
                Create a Counter component with a button to increase the count.
              </li>
              <li>
                Use useEffect to update the document’s title whenever the count
                changes.
              </li>
              <li>Example title: "Count: 3".</li>
              <li>
                Tip: use the javascript method: <b>document.title =''</b> to
                manipulate the DOM
              </li>
            </ul>
          </li>
          <li>
            <h2>Window Width Tracker</h2>
            <ul>
              <b>Goal: Track the width of the browser window and display it.</b>
              <li>
                Use useEffect to add a resize event listener to the window.
              </li>
              <li>
                Display the current window width and update it dynamically as
                the window is resized.
              </li>
              <li>
                Remember to clean up the event listener in the cleanup function.
              </li>
              <li>
                Tip: research for the javascript function to detect the window
                inner width
              </li>
            </ul>
          </li>
          <li>
            <h2>Mouse Position Tracker</h2>
            <ul>
              <b>Goal: Display the current mouse position on the screen.</b>
              <li>Use useEffect to add a mousemove event listener.</li>
              <li>
                Track the x and y coordinates of the mouse and display them.
              </li>
              <li>Ensure the event listener is cleaned up properly.</li>
            </ul>
          </li>
        </ol>

        <hr />
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default TopicUseEff;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicRedux extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>Redux</h1>
        <p>
          Redux is a predictable state container for JavaScript apps, commonly
          used with React for managing and centralizing application state. It’s
          particularly useful when dealing with larger or more complex
          applications where state needs to be accessed by multiple components
          or when components need to share information. Here’s a step-by-step
          guide on how and why to use Redux in React, with examples to clarify
          each concept.
        </p>
        <h2>Why Use Redux?</h2>
        <ol>
          <li>
            <b>Single Source of Truth:</b>Redux creates a single store that
            holds the state of the entire app, simplifying state management and
            making it easier to debug.
          </li>
          <li>
            <b>Easier to Maintain: </b>The centralized state makes it easy to
            understand how data flows and changes in your app.
          </li>
          <li>
            <b>Component Decoupling:</b> Components become less dependent on
            each other, since they can access shared state from the Redux store.
          </li>
          <li>
            <b>Debugging and Testing:</b> Redux provides excellent tools like
            time-travel debugging and the Redux DevTools extension, making it
            easier to track, test, and manage state changes.
          </li>
        </ol>
        <h2>Setting Up Redux with React</h2>
        <ol>
          <li>
            <b>Step 1: Install Redux Packages</b>
            <p>
              To get started, install redux and react-redux, which provides
              bindings for integrating Redux with React.
            </p>
            <CodeDisplay code="npm install redux react-redux" language="sh" />
          </li>
          <li>
            <b>Step 2: Create the Redux Store</b>
            <p>
              The store holds the entire state of the app. In a typical setup,
              you’ll also define actions and reducers.
            </p>
            <b>File Structure: </b>
            <img src="./images/rdu01.png" alt="" />
          </li>
          <li>
            <b>Step 3: Define Actions</b>
            <p>
              Actions are objects that describe changes to the state. These are
              usually defined as functions that return an object with at least a
              type property.
            </p>
            <b>Example: </b>
            <img src="./images/rdu02.png" alt="" />
          </li>
          <li>
            <b>Step 4: Create Reducers</b>
            <p>
              Reducers are functions that determine how the state changes in
              response to actions. Each reducer receives the current state and
              the action, then returns a new state.
            </p>
            <b>Example: </b>
            <img src="./images/rdu03.png" alt="" />
          </li>
          <li>
            <b>Step 5: Create the Store</b>
            <p>
              Combine all reducers (even if you have only one, it’s good
              practice) and create the Redux store.
            </p>
            <b>Example: </b>
            <img src="./images/rdu04.png" alt="" />
          </li>
          <li>
            <b>Step 6: Provide the Store to Your App</b>
            <p>
              Use Provider from react-redux to make the store available to all
              components in the app.
            </p>
            <b>Example: </b>
            <img src="./images/rdu05.png" alt="" />
          </li>
          <li>
            <b>Step 7: Connect Components to the Redux Store</b>
            <p>
              To access the state and dispatch actions, use useSelector to
              access the state and useDispatch to dispatch actions.
            </p>
            <b>Example: </b>
            <img src="./images/rdu06.png" alt="" />
          </li>
        </ol>
        <h2>Summary: How It All Comes Together</h2>
        <ol>
          <li>
            <b>Provider:</b> wraps the app with the Redux store, making it
            available to all child components.
          </li>
          <li>
            <b>Actions:</b> (increment, decrement) define what changes can
            happen to the state.
          </li>
          <li>
            <b>Reducer:</b> (counterReducer) specifies how the state should
            update when an action is dispatched.
          </li>
          <li>
            <b>Components:</b> use useSelector to read from the store and
            useDispatch to trigger state changes.
          </li>
        </ol>
        <h1>Activities (Assignement 04)</h1>
        <ol>
          <li>
            <h2>
              Exercise 1: Recreate the counter exercise demo using react-redux
            </h2>
          </li>
          <li>
            <h2>Exercise 2: Add and Remove Items from a List (demo)</h2>
            <ul>
              <b>
                Goal: Manage a list of items in the Redux store, allowing users
                to add and remove items.
              </b>
              <li>
                Create an array items in the Redux store to hold a list of
                items.
              </li>
              <li>
                Define actions addItem and removeItem to add a new item to the
                list and remove an item by its index.
              </li>
              <li>
                Create a reducer to update the items array in response to these
                actions.
              </li>
              <li>
                Build a React component with:
                <ul>
                  <li>An input field and a button to add a new item.</li>
                  <li>
                    A list displaying all items, with each item having a
                    "remove" button.
                  </li>
                </ul>
              </li>
              <li>
                Clicking the "remove" button should remove the specific item
                from the list.
              </li>
            </ul>
          </li>
          <li>
            <h2>Exercise 3: Set User Profile Data</h2>
            <ul>
              <b>
                Goal: Create a form to store user profile data in Redux and
                display it in a React component.
              </b>
              <li>
                Add an initial profile state in the Redux store, including
                fields like name, age, and email.
              </li>
              <li>
                Create actions to update each field in the profile (setName,
                setAge, and setEmail).
              </li>
              <li>
                Build a reducer to handle these actions and update the profile
                state accordingly.
              </li>
              <li>
                Build a form component with inputs for name, age, and email,
                dispatching actions to update the Redux store with each change.
              </li>
              <li>
                Display the updated profile information in another component.
              </li>
              <li>
                <b>Expected Output:</b> The form should dynamically update the
                Redux state with the entered user profile information and
                display it in real time.
              </li>
            </ul>
          </li>
        </ol>

        <hr />
        <p>reference: https://create-react-app.dev/</p>
        <p>reference: https://react-redux.js.org/</p>
      </div>
    );
  }
}

export default TopicRedux;

import React, { Component } from "react";
import TextExplanation from "../TextExplanation";
import CodeDisplay from "../CodeDisplay";
import { Link } from "react-router-dom";

export class TopicAReuse extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>React Reusable Component</h1>
        <ol>
          <li>
            <h2>A button component</h2>
            <p>
              In this topic we'll learn about how to make a simple react
              component reusable
            </p>
            <p>
              Create a new react app and create a new component that we call
              ReButton for reusable button
            </p>
            <p>Examine the following code for the button component:</p>
            <CodeDisplay
              code='import React from "react";

export default function ReButton() {
  const handleClick = () => {
    alert("Button clicked!");
  };
  return (
    <button
      className="btn btn-primary"
      onClick={handleClick}
      disabled={false}
    >
      Button label
    </button>
  );
}'
              language="javascript"
            />
            <p>
              You'll notice that we created a button with predefined style and a
              function that I can use anywhere inside my app and it will be
              displayed and performing its action.
            </p>
            <p>
              But actually what we created now is a static component whenever I
              use it it will be displayed with the same label, same style, same
              Action predefined.
            </p>
            <p>
              To make this component dynamic (Reusable) we could use a feature
              in React called <b>props</b>
            </p>
            <h2>Props</h2>
            <p>
              In React, props (short for "properties") are objects used to pass
              data from a parent component to a child component. They allow
              components to be reusable and customizable. Props are immutable
              within the child component, meaning that the child component
              cannot modify them directly.
            </p>
            <b>Parent Component:</b>
            <img src="./images/reu01.png" alt="" />
            <b>Child component:</b>
            <img src="./images/reu02.png" alt="" />
            <b>And props could be destructured for a more readable code:</b>
            <img src="./images/reu03.png" alt="" />
          </li>
          <li>
            <p>
              Let's apply this concept to our example of a reusable button by
              calling it in App.js and assigning some properties:
            </p>
            <b>From App.js</b>
            <CodeDisplay
              code='import "./App.css";
import ReButton from "./components/ReButton";
function App() {
  const handleClick = () => {
    alert("Button clicked!");
  };
  return (
    <div className="App">
      <h1>Reusable Button Example</h1>
      <ReButton
        label="Example BTN"
        onClick={handleClick}
        variant="btn-danger"
        disabled={false}
      />
    </div>
  );
}

export default App;'
              language="javascript"
            />
            <b>
              And for the reusable button component (ReButton.jsx) we'll receive
              the props as follow:
            </b>
            <CodeDisplay
              code='import React from "react";

export default function ReButton(props) {
  return (
    <button
      className={`btn ${props.variant}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.label}
    </button>
  );
}'
              language="javascript"
            />
          </li>
          <p>
            now you can pass different values for the lable, style, action
            define if the button is disabled or not whick make it a completely
            reusable component that could be customized anywhere inside my app.
          </p>
        </ol>

        <hr />
        <p>reference: https://create-react-app.dev/</p>
      </div>
    );
  }
}

export default TopicAReuse;
